<router-outlet></router-outlet>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  name="Highlight"
>
  <p style="font-size: 20px; color: white">Highlighting Sentences...</p>
</ngx-spinner>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  name="Fetching"
>
  <p style="font-size: 20px; color: white">Fetching Articles...</p>
</ngx-spinner>