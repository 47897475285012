import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/services/data.service';
import { faBackspace, faExternalLinkAlt, faAngleUp, faAngleDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common'
import { AuthService } from 'src/app/services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Options, LabelType } from "@angular-slider/ngx-slider";

@Component({
  selector: 'app-p1-articles',
  templateUrl: './p1-articles.component.html',
  styleUrls: ['./p1-articles.component.css']
})

export class P1ArticlesComponent implements OnInit {
  faBackspace=faBackspace
  faExternalLinkAlt =faExternalLinkAlt;
  faAngleUp = faAngleUp;
  faAngleDown = faAngleDown;
  faSearch = faSearch;
  searchTypes:any=[
    {"key":"Near Match","value":"FUZZY"},
    {"key":"Broad Match","value":"BROAD"},
    {"key":"Strict Match","value":"STRICT"},
    {"key":"Loose Match","value":"LOOSE"},
  ]
  submitted: boolean = false;
  page: number = 1;
  size: number = 10;
  isSearchResults: boolean = false;
  amlData: any[] = [];
  totalItems: number = 0;
  resultsCount: number = 0;
  isHideFilters: boolean = false;
  keywords: any[] = [];
  filterArray: any[] = [];
  filterKeyArray: any[] = [];
  value: number = new Date().getFullYear() -10;
  highValue: number = new Date().getFullYear() ;
  options: Options = {
    floor: new Date().getFullYear() -10,
    ceil: new Date().getFullYear() ,
    step: 1,
    showTicks: true,
    showTicksValues: true,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "<b>Start Date:</b> " + value;
        case LabelType.High:
          return "<b>End Date:</b> " + value;
        default:
          return "" + value;
      }
    }
  };
  startDate: any;
  endDate: any;
  filterSubmitted: boolean = false;
  filterByValue: any= {};
  sentimentsLessThan: any = null;
  sentimentsGreaterThan: any = null;
  constructor(
    private data:DataService,
    private form: FormBuilder,
    private spinner:NgxSpinnerService,
    private auth:AuthService,
    private location:Location,
  ) { }
  searchForm: FormGroup = this.form.group({
    entityName: ['', Validators.required],
    searchType: ['', Validators.required],
  });
  ngOnInit(): void {
    this.searchForm.patchValue({
      searchType: 'LOOSE'
    })
  }

  get fa() { return this.searchForm.controls; }

  getFilterType(event){
    if(event != 'null'){
      this.filterByValue['key'] = event
    }
    else{
      this.filterByValue['key'] = null
    }
  }

  getFilter(){
    if(this.filterByValue['key'] != 'null' && this.filterByValue.inputVal != ''){
      this.filterKeyArray = [];
      if(this.filterByValue.key == 'gteLte'){
        const tempArray = this.filterByValue.inputVal.split(';');
        this.sentimentsLessThan = Number(tempArray[0].trimStart());
        this.sentimentsGreaterThan = Number(tempArray[1].trimStart());
      }
      else{
        const tempArray = this.filterByValue.inputVal.split(';');
        let obj = { key: this.filterByValue.key, value: [] }
        for(let temp of tempArray){
          obj['value'].push(temp.trimStart());
        }
        this.filterKeyArray.push(obj);
        for(let item of this.keywords){
          if(item.heading == this.filterKeyArray[0].key){
            item.filters.forEach((e) => this.filterKeyArray[0].value.includes(e.key) ? e.value = true : e.value = false);
          }
          else{
            item.filters.map((e) => e.value = false);
          }
        }
      }
      this.getSearchResults(this.page, this.size, this.startDate, this.endDate, this.filterKeyArray, false);
    }
  }

  onSubmitnew(){
    this.filterKeyArray = [];
    this.filterByValue['key'] = null;
    this.filterByValue['inputVal'] = '';
    this.sentimentsGreaterThan = null;
    this.sentimentsLessThan = null;
    this.submitted = true;
    if(this.searchForm.invalid) {
      return;
    }
    this.startDate = new Date(String(this.value)+"-01-01").toISOString();
    this.endDate = new Date(String(this.highValue)+"-12-31").toISOString();
    this.getSearchResults(this.page, this.size, this.startDate, this.endDate, this.filterKeyArray, true);
  }

  getSearchResults(page, size, startDate, endDate, filterKeyArray, searchFilterCalled?: boolean){
    this.amlData = [];
    const payload = {
      "size": size,
      "page": page - 1,
      "sort":null,
      "searchType": this.searchForm.value.searchType,
      "searchBody": this.searchForm.value.entityName,
      "publisherUrl": [],
      "startPublishDate": null, // startDate,
      "endPublishDate": null, // endDate,
      "tags": [],
      "entities": [],
      "entityType": [],
      "sentimentScoreGte": this.sentimentsGreaterThan,
      "sentimentScoreLte": this.sentimentsLessThan,
      "adversityScoreGte": null,
      "adversityScoreLte": null,
      "onlyAdverse": null
    }
    for(let item of filterKeyArray){
      payload[item.key] = item.value;
    }
    this.spinner.show("Fetching");
    this.data.getP1ArticleSearch(payload).pipe(finalize(() => this.spinner.hide('Fetching'))).subscribe((res) => {
      if(res['data'] && res['data'].content && res['data'].content.length){
        if(searchFilterCalled){
          this.getSearchFiltersList(page, size, startDate, endDate);
          this.resultsCount = res['data'].totalElements;
        } 
        this.isSearchResults = true;
        this.amlData = res['data'].content;
        this.totalItems = res['data'].totalElements;
        this.searchForm.patchValue({
          searchType: this.searchForm.value.searchType,
          entityName: this.searchForm.value.entityName
        })
      }
      else{
        this.data.toastrShow(`No Data Found for ${this.searchForm.value.entityName}`, 'warning');
      }
    })
  }

  getSearchFiltersList(page, size, startDate, endDate){
    const payload = {
      "size": size,
      "page": page - 1,
      "sort":null,
      "searchType": this.searchForm.value.searchType,
      "searchBody": this.searchForm.value.entityName,
      "publisherUrl":[],
      "startPublishDate": null, // startDate,
      "endPublishDate": null, // endDate,
      "tags": [],
      "entities": [],
      "entityType": [],
      "sentimentScoreGte": null,
      "sentimentScoreLte": null,
      "adversityScoreGte": null,
      "adversityScoreLte": null,
      "onlyAdverse": null
    }
    this.spinner.show("Fetching");
    this.data.getP1ArticleFilter(payload).pipe(finalize(() => this.spinner.hide('Fetching'))).subscribe((res) => {
      if(res['data']){
        this.keywords = [];
        this.filterArray = [];
        let dataCount = 0;
        for(let key of Object.keys(res['data'])){
          dataCount += 1;
          this.getIndexValueOfApplyFilters(dataCount - 1);
            let keywordsObj = { heading: key , value: false, filters: [] }
            for(let element of res['data'][key]){
              if(element.key != ''){
                let filterObj = { key: element.key, value: false, keyName: "", doc_count: 0 };
                if(element.key.includes('//')){
                  const splitKey = element.key.split('//');
                  filterObj['keyName'] = splitKey[1];
                }
                else{
                  filterObj['keyName'] = element.key;
                }
                filterObj['doc_count'] = element.doc_count;
                keywordsObj['filters'].push(filterObj)
            }
          }
          this.keywords.push(keywordsObj);
        }
      }
    })
  }

  refreshData(event){
    this.page = event;
    this.getSearchResults(this.page, this.size, this.startDate, this.endDate, this.filterKeyArray);
  }

  jumpTo(){
    this.startDate = new Date(String(this.value)+"-01-01").toISOString();
    this.endDate = new Date(String(this.highValue)+"-12-31").toISOString();
    this.getSearchResults(this.page, this.size, this.startDate, this.endDate, this.filterKeyArray);
  }

  getIndexValueOfApplyFilters(i) {
    if (this.filterArray?.indexOf(i) > -1) {
      this.filterArray.splice(this.filterArray.indexOf(i), 1);
    } else {
      this.filterArray.push(i);
    }
  }

  applyAllFilter(index) {
    this.filterKeyArray = [];
    for (var i = 0; this.keywords.length > i; i++) {
      let obj = { key: '', value: [] }
      obj['key'] = this.keywords[i].heading;
      if (i == index) {
        this.keywords[i].filters.map((e) => e.value = this.keywords[i].value );
      }
      this.keywords[i].filters.forEach((item) => {
        if (item.value) {
          obj['value'].push(item.key);
        }
      });
      this.filterKeyArray.push(obj);
    }
    this.getSearchResults(this.page, this.size, this.startDate, this.endDate, this.filterKeyArray);
  }

  applyFilter() {
    this.filterKeyArray = [];
    for(let e of this.keywords){
      let obj = { key: '', value: [] }
      obj['key'] = e.heading;
      e.filters.forEach((item) => {
        if (item.value) {
          obj['value'].push(item.key);
        }
      });
      this.filterKeyArray.push(obj);
    }
    this.getSearchResults(this.page, this.size, this.startDate, this.endDate, this.filterKeyArray);
  }

  logout(){
    this.auth.logout();
  }

  goback(){
    this.location.back()
  }
}
