import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../src/app/services/auth.service'
import { Router } from '@angular/router';
import { DataService } from './services/data.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'adverse-media';
  constructor(
    private router:Router,
    private auth:AuthService,
    private data:DataService
  ) {}
  ngOnInit(){
    
  }
}
