import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from 'src/app/services/data.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, NgForm, FormControl, FormArray } from '@angular/forms';
import {HttpClient,HttpRequest,HttpEvent,HttpEventType,HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import { error } from 'protractor';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import{ AuthService} from '../../services/auth.service'
import { faBackspace } from '@fortawesome/free-solid-svg-icons';
import{Location} from '@angular/common'
@Component({
  selector: 'app-languagedetails',
  templateUrl: './languagedetails.component.html',
  styleUrls: ['./languagedetails.component.css']
})
export class LanguagedetailsComponent implements OnInit {
  faBackspace=faBackspace
  language: any;
  closeResult: string;
 
  sino: []=[];
  langdetail: any;
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private data:DataService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private auth:AuthService,
    private location:Location,
  ) { }

  ngOnInit(): void {
    this.data.getlang().subscribe(
      data=>{
        if(data.statusCode==200){
          
          this.language=data.data
          console.log(this.language)
        }
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
      }
    );
  }
  public createForm: FormGroup= new FormGroup({
    code:new FormControl ('', Validators.required),
   code3:new FormControl ('', Validators.required),
   name:new FormControl ('', Validators.required),
   synonyms:new FormControl([""], Validators.required),
   status:new FormControl ('', Validators.required),
   
  })
  onSubmit(){
    console.log(this.createForm.value)
    let abc={"code":this.createForm.value.code,"code3":this.createForm.value.code3,"synonyms":[this.createForm.value.synonyms],"status":this.createForm.value.status}
    console.log(abc)
    this.data.createlang(abc).subscribe(
      data=>{
        if(data.statusCode==200){

        this.modalService.dismissAll()
        console.log(data)
        }
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
      }
    )
  }
  onClick(event,contentone){
   

    let target = event.target || event.srcElement || event.currentTarget;
    let idAttr = target.attributes.id;
    let id = idAttr.nodeValue;
    
    console.log(id); 
    this.data.getlangByCode(id).subscribe(
      data=>{
        console.log(data)
        this.langdetail=data['data']
        this.modalService.open(contentone, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
    )
  }
  onUpdate(){
    console.log(this.langdetail)
    let updateddata={"code":this.langdetail.code,"name":this.langdetail.name,"code3":this.langdetail.code3,"synonyms":[this.langdetail.synonyms],"status":this.langdetail.status}
    console.log(updateddata)
    this.data.updatelang(updateddata).subscribe(
      data=>{
        if(data.statusCode==200){
        console.log(data)
        this.modalService.dismissAll
        }
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
      }
    )
  }
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  logout(){
    this.auth.logout();
  }
  goback(){
    this.location.back()
  }
}
