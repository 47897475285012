<nav class="navbar navbar-expand-sm bg-dark navbar-dark">
    <a class="navbar-brand" [routerLink]="['/home']" routerLinkActive="router-link-active">
      <img src="assets/images/logo.png" alt="logo" style="height: 40px;">
    </a>
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/adminhome']" style="margin-right: 5px;" routerLinkActive="active">Home</a>
      </li>
  
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/countrydetails']" style="margin-right: 5px;"
          routerLinkActive="active">Countries</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/languagedetails']" style="margin-right: 5px;"
          routerLinkActive="active">Languages</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/datedetails']" style="margin-right: 5px;" routerLinkActive="active">Date</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/adverse-media']" style="margin-right: 5px;" routerLinkActive="active">Adverse
          Media</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/tag-search']" style="margin-right: 5px;" routerLinkActive="active">Tag
          Search</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/media-intelligence']" style="margin-right: 5px;"
          routerLinkActive="active">Media Intelligence</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/p1-articles']" style="margin-right: 5px;"
          routerLinkActive="active">P1 Articles</a>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item mr-2">
        <button class="btn btn-success" [routerLink]="['/home']" routerLinkActive="active">Client</button>
  
      </li>
      <li class="nav-item">
        <button class="btn btn-danger" (click)="logout()">Logout</button>
      </li>
    </ul>
  </nav>
  
  <div class="wrapper">
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
          <a floating="true" (click)="goback()" class="float-left">
            <fa-icon [icon]="faBackspace" class="mt-2 mb-2 ml-2" size="2x"></fa-icon>
          </a>
        </div>
      </div>
      <section *ngIf="!isSearchResults">
        <div class="row mt-4">
          <div class="col-12">
            <form [formGroup]="searchForm" (ngSubmit)="onSubmitnew()">
              <div class="input-center-field">
                <div class="form-group max-width-fix">
                  <label for="entityName">Search Here</label>
                  <input type="text" formControlName="entityName" placeholder="Search Entity By Name/Keyword... "
                    id="entityName" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.entityName.errors }" />
                  <div *ngIf="submitted && fa.entityName.errors" class="invalid-feedback">
                    <div *ngIf="fa.entityName.errors.required">Keyword is required</div>
                  </div>
                </div>
                <div class="form-group max-width-fix">
                  <label for="searchType">Select Search Type</label>
                  <select name="searchType" formControlName="searchType" id="searchType" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && fa.searchType.errors }">
                    <option *ngFor="let option of searchTypes" [value]="option.value">{{option.key}}</option>
                  </select>
                  <div *ngIf="submitted && fa.searchType.errors" class="invalid-feedback">
                    <div *ngIf="fa.searchType.errors.required">Search Type is required</div>
                  </div>
                </div>
                <div style="margin-top: 56px;">
                  <button type="button" class="btn btn-outline-dark" (click)="onSubmitnew()">Search</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
  
      <section *ngIf="isSearchResults">
        <form [formGroup]="searchForm" (ngSubmit)="onSubmitnew()">
          <div class="row mt-4 align-items-end">
            <div class="col-md-4">
              <div class="form-group mb-0">
                <label for="entityName">Search Here</label>
                <input type="text" formControlName="entityName" placeholder="Search Entity By Name/Keyword... "
                  id="entityName" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.entityName.errors }" />
                <div *ngIf="submitted && fa.entityName.errors" class="invalid-feedback">
                  <div *ngIf="fa.entityName.errors.required">Keyword is required</div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group mb-0">
                <label for="searchType">Select Search Type</label>
                <select name="searchType" formControlName="searchType" id="searchType" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && fa.searchType.errors }">
                  <option *ngFor="let option of searchTypes" [value]="option.value">{{option.key}}</option>
                </select>
                <div *ngIf="submitted && fa.searchType.errors" class="invalid-feedback">
                  <div *ngIf="fa.searchType.errors.required">Search Type is required</div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <button type="button" class="btn btn-outline-dark" (click)="onSubmitnew()">Modify Search</button>
            </div>
          </div>
        </form>
        <div class="row mt-4">
          <div class="col-md-4">
            <div class="form-group mb-0">
              <label for="searchBy">Select Filter By</label>
              <select name="searchBy" id="searchBy" (change)="getFilterType($event.target.value)" class="form-control">
                <option value="null">Select Filter By</option>
                <option *ngFor="let option of keywords" [value]="option.heading">{{option.heading}}</option>
                <option value="gteLte">Sentiments Lte Gte Score</option>
              </select>
            </div>
          </div>
          <div class="col-md-4" *ngIf="filterByValue?.key">
            <div class="form-group searcBar mb-0">
              <label for="searchFilter">Enter Value</label>
              <input type="text" [(ngModel)]="filterByValue.inputVal" id="searchFilter" placeholder="Enter the value separated by semicolons..." class="form-control"/>
                <fa-icon [icon]="faSearch" (click)="getFilter()"></fa-icon>
            </div>
          </div>
        </div>
        <div class="row mt-4 align-items-center">
          <div class="col-2">
            <p class="float-right mb-0" style="margin-top: 12px;">Date Range:</p>
          </div>
          <div class="col-10 d-flex justify-content-center">
            <div class="custom-slider">
              <ngx-slider [(value)]="value" [(highValue)]="highValue" [options]="options" (click)="jumpTo()"></ngx-slider>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <div class="card pr-4">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex">
                  <img src="/assets/images/Refine_Search_Light.svg" class="cursor-pointer" title="click to hide the filters" (click)="isHideFilters = !isHideFilters">
                  <img src="/assets/images/Back_Button_Light.svg" [style.transform]="isHideFilters ? 'rotate(180deg)' : ''">
                </div>
                <div class="d-flex align-items-center" style="min-height: 42px;">
                  <p class="mb-0">Articles found <span style="font-weight: 600">{{totalItems}}</span></p>
                  <p class="vertical-line"></p>
                  <p class="mb-0">Articles hidden by filters <span style="font-weight: 600">{{resultsCount - totalItems}}</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div [ngClass]=" !isHideFilters ? 'col-xl-3 col-lg-4 col-md-4' : 'd-none'">
            <ng-container *ngFor="let filter of keywords; let j = index;">
              <div class="card accordion" [ngClass]=" j > 0 ? 'mt-2' : '' " [id]="'keywordsAccordian' + j">
                <div class="accordian-card card-box pt-0 pb-0 pl-2 pr-2 mb-1" [id]="'headingkeywords' + j"
                  [ngClass]="filterArray?.indexOf(j) > -1 ? '' : 'collapsed'" data-toggle="collapse"
                  aria-expanded="false">
                  <div class="lable-group">
                    <div class="customCheckbox label">
                      <input type="checkbox" [id]="filter?.heading + j" [(ngModel)]="filter.value"
                        (change)="applyAllFilter(j)" />
                      <label [for]="filter?.heading + j">{{ filter?.heading}}</label>
                    </div>
                    <div class="icon-i" (click)="getIndexValueOfApplyFilters(j)" style="float: right;">
                      <fa-icon [icon]=" filterArray?.indexOf(j) > -1 ? faAngleUp : faAngleDown "></fa-icon>
                    </div>
                  </div>
                </div>
                <div class="collapse" [id]="'keywordsInfo' + j" aria-labelledby="headingkeywords"
                  [ngClass]="filterArray?.indexOf(j) > -1 ? 'show' : ''">
                  <section class="card-box pt-0 pb-0 pl-2 pr-2 mb-1"
                    style="max-height: 92vh; overflow-y: auto; overflow-x: hidden;">
                    <div class="row">
                      <div class="col-12" *ngFor="let keyword of filter?.filters; let i = index">
                        <div class="d-flex justify-content-between align-items-center" [title]="keyword.key" style="margin-left: 10px;">
                          <div class="customCheckbox label">
                            <input type="checkbox" [id]="j + keyword.key + i" [(ngModel)]="keyword.value"
                            (change)="applyFilter()" />
                            <label [for]="j + keyword.key + i">{{ keyword.keyName}}</label>
                          </div>
                          <div>
                            <p>{{keyword?.doc_count}}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </ng-container>
          </div>
          <div [ngClass]=" !isHideFilters ? 'col-xl-9 col-lg-8 col-md-8' : 'col-12'">
            <ng-container *ngFor="let article of amlData; let i = index">
                <div *ngIf="article?.article as item" class="card p-4" [ngClass]=" i > 0 ? 'mt-4' : '' ">
                    <div class="d-flex">
                      <h5>{{item?.title || '-'}}</h5>
                      <a class="ml-4 cursor-pointer" [href]="item?.article_url" target="_blank" [title]="'Go To ' + item?.article_url"><fa-icon [icon]="faExternalLinkAlt"></fa-icon></a>
                    </div>
                    <div class="d-flex mt-3 justify-content-between">
                      <p style="font-weight: 600;">Author : {{item?.author || 'No author found'}}</p>
                      <p style="font-weight: 600;">Publish Date: {{(item?.publish_date | date : 'dd-MMM-yyyy') || 'Publish date not found'}}</p>
                    </div>
                    <div>
                      <p style="font-weight: 600;">Publisher : {{item?.news_publisher}} | {{item?.news_publisher_url}}</p>
                    </div>
                    <div class="row">
                      <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12 article-image">
                        <img [src]="item?.image_url" onerror="this.src='../../assets/images/profile-picture.png'">
                      </div>
                      <div class="col-xl-9 col-lg-9 col-md-8 col-sm-12" [id]="'articledBodyWithHightlightSentence' + i">
                        <ng-container *ngIf="(article | highlightSentence : i) | async as htmlBody">
                          {{htmlBody}}
                        </ng-container>
                        <!-- <div *ngIf="!item?.read">
                          <p>{{(item?.body | slice : 0:600) || 'Article body not found'}} <span class="cursor-pointer" (click)="item.read = !item.read" style="color: #007BFF;">...Read More</span></p>
                        </div>
                        <div *ngIf="item?.read">
                          <p>{{item?.body || 'Article body not found'}} <span class="cursor-pointer" (click)="item.read = !item.read" style="color: #007BFF;">Read Less</span></p>
                        </div> -->
                      </div>
                    </div>
                  </div>
            </ng-container>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <div class="d-flex justify-content-center">
              <ngb-pagination [(page)]="page" [(collectionSize)]="totalItems" [(pageSize)]="size"
                (pageChange)="refreshData($event)" [maxSize]="3" [rotate]="true" [boundaryLinks]="true">
              </ngb-pagination>
            </div>
          </div>
        </div>
      </section>
      
    </div>
  </div>