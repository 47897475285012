import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from 'src/app/services/data.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, NgForm, FormControl } from '@angular/forms';
import {HttpClient,HttpRequest,HttpEvent,HttpEventType,HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import { error } from 'protractor';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import{ AuthService} from '../../services/auth.service'
import { faBackspace } from '@fortawesome/free-solid-svg-icons';
import{Location} from '@angular/common'
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.css']
})
export class RegisterUserComponent implements OnInit {
  faBackspace=faBackspace
  registerForm: FormGroup;
  submitted: boolean;
  loading: boolean;
  error: any;
  closeResult: string;
  error_messages = {
    'firstName': [
      { type: 'required', message: 'First Name is required.' },
    ],
    'email': [
      { type: 'required', message: 'Email is required.' },
      { type: 'minlength', message: 'Email length.' },
      { type: 'maxlength', message: 'Email length.' },
      { type: 'required', message: 'please enter a valid email address.' }
    ],
    'contactNumber': [
      { type: 'required', message: 'Contact Number is required.' },
    ],
    'password': [
      { type: 'required', message: 'password is required.' },
      { type: 'minlength', message: 'password length.' },
      { type: 'maxlength', message: 'password length.' }
    ],
    'confirmPassword': [
      { type: 'required', message: 'password is required.' },
      { type: 'minlength', message: 'password length.' },
      { type: 'maxlength', message: 'password length.' }
    ],
  };
  setForm:FormGroup;
  usertoken: any;
  constructor(
    private modalService: NgbModal,
    public router:Router,
    public activatedRoute:ActivatedRoute,
    private data:DataService,
   
    private auth:AuthService,
    private location:Location,
    private spinner:NgxSpinnerService,
    
    private fb:FormBuilder
  ) 
  {
    this.registerForm = this.fb.group({
      firstName:new FormControl ('', Validators.compose([Validators.required])),
      email: new FormControl('', Validators.compose([Validators.required,
        Validators.minLength(6),
        Validators.maxLength(50)])),
      contactNumber:new FormControl('',Validators.compose([Validators.required,
        Validators.minLength(9),
        Validators.maxLength(11)]))
      
    });
    this.setForm = this.fb.group({
    password:new FormControl ('', Validators.compose([Validators.required])),
    confirmPassword: new FormControl('', Validators.compose([Validators.required,
      Validators.minLength(6),
      Validators.maxLength(50)])),
    
    
    },
    { 
      validators: this.password.bind(this)
    }
    );

  }

  ngOnInit() {
    
     }
     
  get f() { return this.registerForm.controls; }
  onSubmit(content) {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }
    

    this.loading = true;
    console.log(this.registerForm.value);
    this.spinner.show()
    this.data.createUser(this.registerForm.value).subscribe(
      data=>{
        if(data.statusCode==200){
          this.spinner.hide()
          this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',backdrop:'static'})

          console.log(data)
          this.usertoken=data['data'].token
          console.log(this.usertoken)
          let alertObj = {
            message:data.message,
            status: data.infoType,
            autoDismiss: true,
            timeToDismiss: 5000
          };
          this.data.toastrShow(alertObj.message,alertObj.status)
        }
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        this.spinner.hide()
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )

  }
  password(formGroup: FormGroup) {
    const { value: password } = formGroup.get('password');
    const { value: confirmPassword } = formGroup.get('confirmPassword');
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }
  onSubmitnew(){
    console.log(this.setForm.value)
    let abc={"password":this.setForm.controls.password.value,"confirmPassword":this.setForm.controls.confirmPassword.value,"token":this.usertoken}
    console.log(abc)
    this.spinner.show()
    this.data.setUpAccount(abc).subscribe(
      data=>{
        console.log(data)
        this.spinner.hide();
        this.modalService.dismissAll();
        this.setForm.reset();
        this.registerForm.reset();
        let alertObj = {
          message:data.message,
          status: data.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        this.spinner.hide();
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 3000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
  }
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',backdrop:'static'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  logout(){
    this.auth.logout();
  }
  goback(){
    this.location.back();
  }
}
