import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import{AuthService} from '../../services/auth.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/app/models/user';
import{DataService} from '../../services/data.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  returnUrl: string;
  submitted: boolean;
  loading = false;
  error = '';
  public loginForm: FormGroup= new FormGroup({
   username:new FormControl ('', Validators.required),
    password:new FormControl ('', Validators.required),
  });
  token: any;
  isAdmin: boolean;
  
  constructor(
    private router:Router,
    private auth:AuthService,
    private data:DataService
  ) {
    
    if (this.auth.userValue) { 
      this.router.navigate(['/home']);
  }
  // else{
  //   this.router.navigate(['/login'])
  // }
   }

  ngOnInit(){
    
  }
  get f() { return this.loginForm.controls; }
  onSubmit(){
    // console.log(this.loginForm.value);
    this.submitted = true;
    if(this.loginForm.invalid) {
      return;
      
  }
  this.loading = true;
  console.log(this.loginForm.value);
  this.auth.userAuthentication(this.loginForm.value)
    
            .subscribe(
                data => {
                  
                  
                    this.loading=false;
                  console.log("login",data)
                  this.token=data.access_token
                  console.log("token",this.token)
                  let alertObj = {
                    message:"Logged In Successfully!",
                    status: "SUCCESS",
                    autoDismiss: true,
                    timeToDismiss: 5000
                  };
                  this.data.toastrShow(alertObj.message,alertObj.status)
                  this.check()
                  
                 
                },
                (err:HttpErrorResponse )=> {
                    console.log(err)
                    let alertObj = {
                      message:err.error.message,
                      status: err.error.infoType,
                      autoDismiss: true,
                      timeToDismiss: 5000
                    };
                    this.data.toastrShow(alertObj.message,alertObj.status)
                    this.loading = false;
                });
                
}
check(){
  this.auth.checkProfile().subscribe(
    data=>{
      const roles=this.auth.roles
        console.log("role",roles)
        if(roles.slug=='ROLE_SUPERADMIN'){
          this.isAdmin=true
          this.router.navigate(['/adminhome'])
        }
        else{
          this.isAdmin=false
          this.router.navigate(['/home'])
        }
    }
  )       
}
}
