import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from 'src/app/services/data.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, NgForm, FormControl } from '@angular/forms';
import {HttpClient,HttpRequest,HttpEvent,HttpEventType,HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import { error } from 'protractor';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import{ AuthService} from '../../services/auth.service'
import { faBackspace,faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import{Location} from '@angular/common'
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-adverse-media',
  templateUrl: './adverse-media.component.html',
  styleUrls: ['./adverse-media.component.css']
})
export class AdverseMediaComponent implements OnInit {
  faBackspace=faBackspace
  faExternalLinkAlt =faExternalLinkAlt 
  eventData: any;
  name :any=null;
  IdeventData: any;

  page : number=0;
  size : number=20;
  order : any;
  orderBy : any;
  type: Array<string>=["PERSON","ORGANISATION"] ;
  eventStatus : any =null;
  processStatus: any
  advData: any;
  collectionSize: any;
  searchTerm: Array<string>=[""];
  selected:Array<string>
  val: any[];
  status: Array<string>=[""];
  processedStatus: any;
  aggSocres: any;
  articleList: any;
  emptyTags: boolean=false;
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private data:DataService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private auth:AuthService,
    private location:Location,
    private spinner:NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.selected=["PERSON"]
    this.processStatus="LEVEL3"
    this.processedStatus="adverseMediaDemo2"
   
    this.getByName(this.name)
    
    
  }
  getSearchResult(page=this.page,size=this.size,type=this.selected,name=this.name,processStatus=this.processStatus,processedStatus=this.processedStatus){
    
   
    let abc={
      "page":page,
      "size":size,
      "type":type,
      "name":[name],
      "processStatus":[processStatus],
      "processedStatus":[processedStatus]
    }
    this.spinner.show("Fetching")
    this.data.searchAdvMedia(abc).subscribe(
      data=>{
        if(data.statusCode==200){
          
          console.log(data)
          this.spinner.hide("Fetching")
          this.advData=data['data'] && data['data']['content']
          console.log(this.advData)
          this.collectionSize=data.data && data.data.totalElements
          
        }
        else if(data.data.content==null){
          console.log("iff",data)
          let alertObj = {
            message:"data.data",
            status: "INFO",
            autoDismiss: true,
            timeToDismiss: 10000
          };
          this.data.toastrShow(alertObj.message,alertObj.status)
        }
       else if(data.data.content.length==0){
          let alertObj = {
            message:"Data Not Found!",
            status: data.infoType,
            autoDismiss: true,
            timeToDismiss: 5000
          };
          this.data.toastrShow(alertObj.message,alertObj.status)
        }
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        this.spinner.hide("Fetching")
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    )
    
  }
 getByName(event){
   console.log(event)
  this.getSearchResult(this.page,this.size,this.selected,event,this.processStatus,this.processedStatus)
  this.getByEntityAggScore(event,this.processStatus,this.processedStatus);
 }
  getByFilter(event){
    console.log(event)
    this.selected=[]
    this.selected.push(event)
   
   this.getSearchResult(this.page,this.size,this.selected,this.name,this.processStatus,this.processedStatus)
    
  }
  getByStatus(event){
    console.log(event)
    console.log(this.processStatus)
    
    this.getSearchResult(this.page,this.size,this.selected,this.name,this.processStatus,this.processedStatus)
    this.getByEntityAggScore(this.name,event,this.processedStatus)
  }
  getByProcessedStatus(event){
    console.log(event)
    console.log(this.processedStatus)
    this.getSearchResult(this.page,this.size,this.selected,this.name,this.processStatus,this.processedStatus)
    this.getByEntityAggScore(this.name,this.processStatus,event)
  }
  // increaseSize(event){
  //   console.log(event)
  //   this.getSearchResult(this.page,event,this.val,this.name,this.status)
  // }
  refreshData(event){
    let pages=(event-1)
    console.log(pages)
    this.getSearchResult(pages,this.size,this.selected,this.name,this.processStatus,this.processedStatus);
  }
  onClick(id){
    console.log(id)
    window.open
    this.router.navigate([]).then(result=>{window.open(`/adv-details/${id}`, '_blank');});
  }
    getByEntityAggScore(name,processStatus,processedStatus){
      this.articleList=[]
      let ob={

       "entity":name,
        "processStatus":processStatus,
      "processedStatus":processedStatus
    }
    this.spinner.show()
      this.data.gerAggScoreTagsArticle(ob).subscribe(
        data=>{
          if(data.statusCode==200 ){
           
            this.aggSocres=data['data']['tags']
            this.spinner.hide()
            console.log(this.aggSocres)
          }
          
        
          
        },
        (err:HttpErrorResponse)=>{
          this.spinner.hide()
          let alertObj = {
            message:err.error.message,
            status: err.error.infoType,
            autoDismiss: true,
            timeToDismiss: 5000
          };
          this.data.toastrShow(alertObj.message,alertObj.status)
        }
      )
      
      console.log(this.articleList)
    }
    tagSelected(article,score){
      console.log(article,score)
      this.articleList=article
      
    }
    onSelectId(id){
      console.log(id)
      window.open
      this.router.navigate([]).then(result=>{window.open(`/adv-details/${id}`, '_blank');});
    }
  logout(){
    this.auth.logout();
  }
  goback(){
    this.location.back()
  }
}
