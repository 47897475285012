<nav class="navbar navbar-expand-sm bg-dark navbar-dark">
  <!-- Brand/logo -->
  <a class="navbar-brand" [routerLink]="['/home']" routerLinkActive="router-link-active" >
    <img src="assets/images/logo.png" alt="logo" style="height: 40px;">
  </a>
  <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/adminhome']" style="margin-right: 5px;" routerLinkActive="active" >Home</a>
      </li>
      
      <li class="nav-item">
          <a class="nav-link" [routerLink]="['/countrydetails']" style="margin-right: 5px;" routerLinkActive="active" >Countries</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/languagedetails']" style="margin-right: 5px;" routerLinkActive="active" >Languages</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/datedetails']" style="margin-right: 5px;" routerLinkActive="active" >Date</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/adverse-media']" style="margin-right: 5px;" routerLinkActive="active" >Adverse Media</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/tag-search']" style="margin-right: 5px;" routerLinkActive="active" >Tag Search</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/media-intelligence']" style="margin-right: 5px;" routerLinkActive="active" >Media Intelligence</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/p1-articles']" style="margin-right: 5px;"
            routerLinkActive="active">P1 Articles</a>
        </li>
    </ul>
  <ul class="navbar-nav ml-auto">
    <li class="nav-item mr-2">
      <button class="btn btn-success" [routerLink]="['/home']" routerLinkActive="active" >Client</button>

  </li>
      <li class="nav-item">
          <button class="btn btn-danger" (click)="logout()">Logout</button>

      </li>
  </ul>
  
  <!-- Links -->
  
</nav>
  <span class="float-left">
    <a  floating="true" (click)="goback()"    ><fa-icon [icon]="faBackspace" class="mt-2 mb-2 ml-2" size="2x"></fa-icon></a>
        
  </span>
  
  <div class="container-fluid ">
    <!-- <div class="d-flex justify-content-center" >
    <input  width="50%" type="text" name="search" autocomplete="off" placeholder="Search">
    </div> -->
  <span class="float-right">
    <button class="btn btn-info" (click)="open(content)" id="create-btn">Add New Country</button>
        
  </span>
  <div class="d-flex justify-content-center table-responsive">
       <table class="table card-table table-striped ">
        <thead class="thead-light">
          <tr>
             <th>ID</th>    
            <th>Pattern</th>
            <th>Example</th>
            <th>Created by</th>  
            <th>Actions</th>
            
          </tr>
            
        </thead>
        <tbody>
          <tr *ngFor="let item of datepattern">
            <td >{{item.datePatternID}}</td>
            <td >{{item.pattern}}</td> 
            <td>{{item.exampleDate}}</td>
            <td>{{item.createdBy.firstName}}</td>
            
            <td><button class="btn btn-warning" (click)="onClick($event,contentone)" id="{{item.datePatternID}}" >
              Edit
            </button>
          </td>
          </tr>
          <tr >
                        
            
          </tr>
        </tbody>
      </table>    
  </div>
</div>
<ng-template #content  let-modal >
    <div class="modal-header" style="padding: 1rem;">
      <h4 class="modal-title" id="modal-basic-title">Create Date .</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      
        <form [formGroup]="updateForm" (ngSubmit)="onSubmitnew()">
            <div class="form-group">
                <label for="pattern">pattern</label>
                <input type="text" formControlName="pattern"   class="form-control"  />
                
            </div>
            <div class="form-group">
                <label for="exampleDate">Example Date</label>
                <input type="text" formControlName="exampleDate"  class="form-control" />
                
            </div>
            
            
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                <button type="submit" class="btn btn-success">
                     Save
                </button>
            </div>
            
        </form>
        </div>
    </ng-template>
    <ng-template #contentone  let-modal >
        <div class="modal-header" style="padding: 1rem;">
          <h4 class="modal-title" id="modal-basic-title">Update Date.</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form (ngSubmit)="onUpdate()">
                <div class="form-group">
                    <label for="datePatternID">Id</label>
                    <input disabled type="text" ngDefaultControl [ngModelOptions]="{standalone: true}" [(ngModel)]="datedetail.datePatternID"  class="form-control"  />
                    
                </div>
                <div class="form-group">
                    <label for="pattern">Pattern</label>
                    <input type="text"  ngDefaultControl [ngModelOptions]="{standalone: true}" [(ngModel)]="datedetail.pattern" name="datedetail.pattern"  class="form-control" />
                    
                </div>
                <div class="form-group">
                    <label for="exampleDate">Example Date</label>
                    <input type="text" ngDefaultControl [ngModelOptions]="{standalone: true}" [(ngModel)]="datedetail.exampleDate" name="datedetail.exampleDate"    class="form-control" />
                    
                </div>
                
                
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                    <button type="submit" class="btn btn-success">
                         Save
                    </button>
                </div>
                
            </form>
            </div>
        </ng-template>