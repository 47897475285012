import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from 'src/app/services/data.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, NgForm, FormControl, FormArray } from '@angular/forms';
import {HttpClient,HttpRequest,HttpEvent,HttpEventType,HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import { error } from 'protractor';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import{ AuthService} from '../../services/auth.service'
import { faBackspace } from '@fortawesome/free-solid-svg-icons';
import{Location} from '@angular/common'
@Component({
  selector: 'app-countrydetails',
  templateUrl: './countrydetails.component.html',
  styleUrls: ['./countrydetails.component.css']
})
export class CountrydetailsComponent implements OnInit {
  faBackspace=faBackspace
  countries: any;
  submitted: boolean;
  closeResult: string;
  synonyms:Array<string>=[]
  updatecountry: any;
  page: number=0;
  pageSize: number=10;
  sources:Array<any>=[]
  collectionSize: any;
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private data:DataService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private auth:AuthService,
    private location:Location,
  ) { }

  ngOnInit(): void {
    let abc={
      "page" : this.page,
      "size" : this.pageSize,
      "order" : "ASC",
      "orderBy" : "createdDate",
      "status" : "ACTIVE"
  }
    this.data.getCountryFilter(abc).subscribe(
      data=>{
        if(data.statusCode==200){
          console.log(data)
          this.countries=data['data']['content']
          this.collectionSize=data['data'].totalElements
          this.sources=this.countries
          console.log('upcountrry',this.countries)
        }
      }
    )
  }
  refreshData() {
    let new2 ={"page" : this.page-1,"size" : this.pageSize,"order" : 'ASC',"orderBy" : 'createdDate'}

    this.data.getCountryFilter(new2).subscribe(
      data=>{
        console.log(data)
        this.countries=data['data']['content']
        this.sources =this.countries

      
      // .slice((this.page - 1) * this.size, (this.page - 1) * this.size + this.size);
    console.log(this.sources)
      }
    )
    
    }
  logout(){
    this.auth.logout();
  }
  goback(){
    this.location.back()
  }
  public updateForm: FormGroup= new FormGroup({
    code:new FormControl ('', Validators.required),
   name:new FormControl ('', Validators.required),
   synonyms:new FormControl ('', Validators.required),
   status:new FormControl ('', Validators.required),
  })
  get fa() { return this.updateForm.controls; }
  onSubmitnew(){
    // console.log(this.updateForm.value);
  //   this.submitted = true;
  //   if(this.updateForm.invalid) {
  //     return;
      
  // }

  console.log(this.updateForm.value.synonyms)
  let abc=new Object
  abc={name:this.updateForm.value.name,code:this.updateForm.value.code,synonyms:[this.updateForm.value.synonyms],status:this.updateForm.value.status}
console.log(abc)
this.data.createCountry(abc).subscribe(
  data=>{
    if(data.statusCode==200){
      console.log(data)
      this.modalService.dismissAll()
    }
  },
  (err:HttpErrorResponse)=>{
    console.log(err)
  }
)
}
onClick(event,contentone){
   

  let target = event.target || event.srcElement || event.currentTarget;
  let idAttr = target.attributes.id;
  let id = idAttr.nodeValue;
  console.log(id)
  this.data.getCountryByCode(id).subscribe(
    data=>{
      if(data.statusCode==200){
        console.log(data)
        this.updatecountry=data['data']
        this.modalService.open(contentone, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }

    },
    (err:HttpErrorResponse)=>{
      console.log(err)
    }
  )
}
onUpdate(){
  console.log(this.updatecountry)
  this.data.updateCountry(this.updatecountry).subscribe(
    data=>{
      if(data.statusCode==200){
        console.log(data)
        this.modalService.dismissAll()
      }
    },
    (err:HttpErrorResponse)=>{
      console.log(err)
    }
  )
}
open(content) {
  this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
}
private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
    return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    return 'by clicking on a backdrop';
  } else {
    return `with: ${reason}`;
  }
}
}
