import { Component, OnInit, Input } from '@angular/core';
import {HttpClient,HttpRequest,HttpEvent,HttpEventType,HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import{ DataService } from '../../services/data.service'
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import{ AuthService} from '../../services/auth.service'
import { concat, Subject, BehaviorSubject } from 'rxjs';
import { stringify } from 'querystring';
import{Shape} from '../../models/shape';
import { Location } from '@angular/common';
import { faBackspace } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-image-labelling',
  templateUrl: './image-labelling.component.html',
  styleUrls: ['./image-labelling.component.css']
})
export class ImageLabellingComponent implements OnInit {
  sub: any;
  faBackspace=faBackspace
  id: any;
  image: any;
  main: any;
  imagenew: any;
  imgheight:number;
  imgwidth:number;

  // @Input() currentShape: Subject<Shape>=new Subject<Shape>();
  currentShape = new BehaviorSubject<Shape>(null);
  abc: { imageDetails: { height: number; width: number; }; lables: Shape[]; };
  closeResult: string;
   shapesToDraw:Array<Shape>

  shapeType = 'rectangle';
  
  labelled:Array<Shape>
  constructor(
    private location:Location,
    private auth:AuthService,
    private http:HttpClient,
    private data:DataService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private router:Router,
    private route: ActivatedRoute,
    private toastr:ToastrService,
    private spinner:NgxSpinnerService,
    
    
  ) { }
 
  setType(type: string) { this.shapeType = "rectangle" }

  // shapes= this.createdShape
  
  findShape=(x) => x < 0 ? 1 : x + 1;
  
  
  
  ngOnInit(): void {
    this.sub=this.route.snapshot.paramMap.get('id');
    console.log(this.sub)
  //   this.sub = this.activatedRoute.queryParams.subscribe(params => {
      
  //     console.log("runn",params)
  //     this.id=params
  //     console.log(this.id);
  // });
  
  this.data.getImageUrl(this.sub).subscribe(
    data=>{
      if(data.data!==null){
        
        this.image=data['data']
        
        var app =("data:image/jpeg;base64,")
        this.imagenew=app.concat(this.image.toString())
        this.spinner.hide()
        // console.log(this.imagenew)
       
        
      }
      else if(data.statusCode!==200){
        this.spinner.hide();
        let alertObj = {
          message:"Image not available.",
          status: "danger",
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
      }
    },
    (err:HttpErrorResponse)=>{
      console.log(err)
      
      let alertObj = {
        message:err.error.message,
        status: err.error.infoType,
        autoDismiss: true,
        timeToDismiss: 5000
      };
      this.data.toastrShow(alertObj.message,alertObj.status)
    }
  )
  this.data.getImageSize(this.sub).subscribe(
    data=>{
      if(data.data!==null){
        console.log(data)
        this.imgheight=data['data']['imageDetails']['height']
        this.imgwidth=data['data']['imageDetails']['width']
        console.log(this.imgwidth)
        this.labelled=data['data']['lables']
        if(this.labelled==null){
          this.shapesToDraw=[]
          console.log("none",this.shapesToDraw)
          }
          else if(this.labelled !== null){
            this.shapesToDraw=this.labelled
            
            console.log("all",this.labelled)
        }
      }
    },
    (err:HttpErrorResponse)=>{
      console.log(err)
      let alertObj = {
        message:err.error.message,
        status: err.error.infoType,
        autoDismiss: true,
        timeToDismiss: 5000
      };
      this.data.toastrShow(alertObj.message,alertObj.status)

    }
  )

  
  this.currentShape.subscribe(x=>{
  this.createdShape=x
  });
      
  }
  createdShape:Shape;
  

  
  startDrawing(evt: MouseEvent) {

    this.createdShape = {

      xCordinates: evt.offsetX,

      yCordinates: evt.offsetY,

      width: 0,

      height: 0,
      name:''
    };
    
    this.shapesToDraw.push(this.createdShape)
    
  }





  keepDrawing(evt: MouseEvent) {

    if (this.createdShape){

      this.currentShape.next(this.createdShape);

      this.createdShape.width = evt.offsetX - this.createdShape.xCordinates;

      this.createdShape.height = evt.offsetY - this.createdShape.yCordinates;
      
      
    }
    
    

  }
  stopDrawing(evt) {

    this.createdShape = null;

  }
  shapeChanged(event:MouseEvent){
    
    this.currentShape.next(this.createdShape);
      
  }
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  getDismissReason(reason: any) {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

onSubmit(){
  this.abc={
    imageDetails:{
      height:this.imgheight,
      width:this.imgwidth
    },
    lables:this.shapesToDraw
  }
  console.log(this.abc)
  
  this.data.updateLabels(this.sub,this.abc).subscribe(
    data=>{
      if(data.statusCode==200){
        this.modalService.dismissAll();
        console.log(data)
        let alertObj = {
          message:data.message,
          status: data.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
        this.router.navigate(["/home"])
      }
    },
    (err:HttpErrorResponse)=>{
      console.log(err)
      let alertObj = {
        message:err.error.message,
        status: err.error.infoType,
        autoDismiss: true,
        timeToDismiss: 5000
      };
      this.data.toastrShow(alertObj.message,alertObj.status)

    }
  )

}


  
  delete(id:any){
   
    this.shapesToDraw.splice(id,1)
  }
  logout(){
    this.auth.logout();
  }
  
  goback(){
    this.location.back()
  }
  ngAfterViewInit() {
    function dragElement(elmnt) {
      var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
      if (document.getElementById(elmnt.id + "header")) {
        /* if present, the header is where you move the DIV from:*/
        document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
      } else {
        /* otherwise, move the DIV from anywhere inside the DIV:*/
        elmnt.onmousedown = dragMouseDown;
      }

      function dragMouseDown(e) {
        e = e || window.event;
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      function elementDrag(e) {
        e = e || window.event;
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
      }
    }
    dragElement(document.getElementById(("mydiv")));
  }
}
