import { Component, OnInit, PipeTransform } from '@angular/core';
import {HttpClient,HttpRequest,HttpEvent,HttpEventType,HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import{ DataService } from '../../services/data.service'
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { faNewspaper } from '@fortawesome/free-solid-svg-icons';
import{ AuthService} from '../../services/auth.service'
import { first } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

function search(text: string, pipe: PipeTransform): any {
  return this.sources.filter(name => {
    const term = text.toLowerCase();
    return name.name.toLowerCase().includes(term)
        || pipe.transform(name.dataSourceId).includes(term)
        || pipe.transform(name.country).includes(term);
  });
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  selectedItems: Array<any> = [];
  dropdownSettings: any = {};
  dropdownList: Array<any> = [];
  searchText:any;
   new:{page : number,size : number,order : string,orderBy : string,countryCode : string}
   newdata:Array<any>=[];
  id: string;
  closeResult: string;
  loginForm: FormGroup;
  submitted: boolean;
  loading = false;
  websiteTag=[] 
  countries: any;
  language: any;
  countryCode:any
  selectedOption:any;
  faNewspaper=faNewspaper;
  date: any;
  role: any;
  isAdmin: boolean;
  page:number = 0;
  pageSize :number= 10;
  collectionSize :number;
  sources:Array<any>=[]
  new2: { page: number; size: number; order: string; orderBy: string; countryCode: any; };

  
  constructor(
    private auth:AuthService,
    private http:HttpClient,
    private data:DataService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private router:Router,
    private route: ActivatedRoute,
    private toastr:ToastrService,
    private spinner:NgxSpinnerService,

  ) {
    
   }

  ngOnInit()  {
    
    this.new ={"page" :this.page,"size" : this.pageSize,"order" : 'ASC',"orderBy" : 'dataSourceName',"countryCode" : this.selectedOption}
    const roles=this.auth.roles
          console.log("role",roles)
          if(roles.slug=='ROLE_SUPERADMIN'){
            this.isAdmin=true
            this.router.navigate[('/adminhome')]
          }
          else{
            this.isAdmin=false
          }
      
    // this.auth.checkProfile().subscribe(
    //   data=>{
    //     const roles=this.auth.roles
    //       console.log("role",roles)
    //       if(roles.slug=='ROLE_SUPERADMIN'){
    //         this.isAdmin=true
    //         this.router.navigate[('/adminhome')]
    //       }
    //       else{
    //         this.isAdmin=false
    //       }
    //   }
    // )
    

    this.data.getcountry().subscribe(
      data=>{
        if(data.statusCode==200){
          
          console.log(data)
          this.countries=data['data']
        }
      }
    )
    this.data.getlang().subscribe(
      data=>{
        if(data.statusCode==200){
          console.log(data)
          this.language=data['data']
        }
      }
    );
    this.data.getDatePattern().subscribe(
      data=>{
        if(data.statusCode==200){
          console.log(data)
          this.date=data.data
          console.log(this.date)
        }
      }
    );
    console.log(this.countryCode);
    this.loginForm = this.formBuilder.group({
      dataSourceName: ['', Validators.required],
      dataSourceURL: ['', Validators.required],
      countryCode: ['', Validators.required],
      languageCode: ['', Validators.required],
      websiteTag: [["World", "Business", "Politics", "Health", "Education & Family", "Science & Environment", "Technology", "Entertainment & Arts"], Validators.required],
      crawlableNormally: ['', Validators.required],
      datePattern: ['', Validators.required],
      sampleArticleUrl: ['', Validators.required],
      searchUrl: ['', Validators.required]
      
  });
    console.log(this.new)
    this.spinner.show()
    this.data.getdatasources(this.new).subscribe(
      data=>{
        
        if(data.statusCode==200){
         
          console.log(data)
          this.newdata=data['data']['content'];
          this.collectionSize=data['data'].totalElements
          // this.page=data['data'].totalPages
          this.sources=this.newdata
          this.spinner.hide()
          console.log(this.newdata)
        }
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
        let alertObj = {
          message:err.error.message,
          status: err.error.infoType,
          autoDismiss: true,
          timeToDismiss: 5000
        };
        this.data.toastrShow(alertObj.message,alertObj.status)
        this.spinner.hide()
      }
    );
    this.dropdownList=['World','Business','Politics','Health','Education & Family','Science & Environment','Technology','Entertainment & Arts','Economy','Sports']
  
    

    this.selectedItems = this.websiteTag
  this.dropdownSettings = {
    singleSelection: false,
    
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 300,
    allowSearchFilter: true
  };
  }
  refreshData(event) {
    
      let pages=(event-1)
      console.log(pages)
      this.new2 ={"page" :pages ,"size" : this.pageSize,"order" : 'ASC',"orderBy" : 'dataSourceName',"countryCode" : this.selectedOption}
      this.spinner.show()
      this.data.getdatasources(this.new2).subscribe(
        data=>{
          console.log(data)
          this.newdata=data['data']['content']
          this.sources =this.newdata
          this.spinner.hide()
  
        
        // .slice((this.page - 1) * this.size, (this.page - 1) * this.size + this.size);
      console.log(this.sources)
        },
        (err:HttpErrorResponse)=>{
          console.log(err)
          let alertObj = {
            message:err.error.message,
            status: err.error.infoType,
            autoDismiss: true,
            timeToDismiss: 5000
          };
          this.data.toastrShow(alertObj.message,alertObj.status)
          this.spinner.hide()
        }
      )
    

    
    
    }
    getSourceByName(){
      console.log(this.searchText)
      let name=this.searchText
      this.data.getSourceByName(name).subscribe(
        data=>{
          console.log(data)
          console.log(this.sources)
          this.sources=[data['data']]
          const arrayOfObj = Object.entries(this.sources).map((e) => ( { [e[0]]: e[1] } ));
        console.log(arrayOfObj)
        }
      )
    }
    getSourceByURL(){
      console.log(this.searchText)
      let name=this.searchText
      this.data.getSourceByURL(name).subscribe(
        data=>{
          console.log(data)
          console.log(this.sources)
          this.sources=[data['data']]
          const arrayOfObj = Object.entries(this.sources).map((e) => ( { [e[0]]: e[1] } ));
        console.log(arrayOfObj)
        }
      )
    }
  get f() { return this.loginForm.controls; }
  onSubmit(){
    this.submitted = true;

      // stop here if form is invalid
      if (this.loginForm.invalid) {
          return;
      }

      this.loading = true;
      console.log(this.loginForm.value);
      
      this.data.createDataSource(this.loginForm.value).subscribe(
        data=>{
          if(data.data!==null){
          this.loading=false
          this.modalService.dismissAll()
          console.log(data)
          let alertObj = {
            message:data.message,
            status: data.infoType,
            autoDismiss: true,
            timeToDismiss: 5000
          };
          this.data.toastrShow(alertObj.message,alertObj.status)
          setTimeout(
            function(){ 
            location.reload(); 
            }, 3000);
          }
          else if(data.data==null){
            this.loading=false
            let alertObj = {
              message:"Data source Not created",
              status: "INFO",
              autoDismiss: true,
              timeToDismiss: 5000
            };
            this.data.toastrShow(alertObj.message,alertObj.status)
            setTimeout(
              function(){ 
              location.reload(); 
              }, 3000);
          }
          
        },
        (err:HttpErrorResponse)=>{
          console.log(err)
          this.loading=false
          let alertObj = {
            message:err.error.message,
            status: err.error.infoType,
            autoDismiss: true,
            timeToDismiss: 5000
          };
          this.data.toastrShow(alertObj.message,alertObj.status)
        }
      )
  }
  op(){
    console.log(this.selectedOption)
  }
  onClick(event){
   

    let target = event.target || event.srcElement || event.currentTarget;
    let idAttr = target.attributes.id;
    let id = idAttr.nodeValue;
    
    console.log(id); 
    this.router.navigate(['/editsource',id])

  }
  onClicknew(event){
   

    let target = event.target || event.srcElement || event.currentTarget;
    let idAttr = target.attributes.id;
    let id = idAttr.nodeValue;
    
    console.log(id); 
    this.router.navigate(['/rssfeed',id])
    
  }
  
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  logout(){
    this.auth.logout();
  }
  
}
