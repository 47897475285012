<nav class="navbar navbar-expand-sm bg-dark navbar-dark">
    <!-- Brand/logo -->
    <a class="navbar-brand" [routerLink]="['/home']" routerLinkActive="router-link-active" >
      <img src="assets/images/logo.png" alt="logo" style="height: 40px;">
    </a>
    <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/adminhome']" style="margin-right: 5px;" routerLinkActive="active" >Home</a>
        </li>
        
        <li class="nav-item">
            <a class="nav-link" [routerLink]="['/countrydetails']" style="margin-right: 5px;" routerLinkActive="active" >Countries</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/languagedetails']" style="margin-right: 5px;" routerLinkActive="active" >Languages</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/datedetails']" style="margin-right: 5px;" routerLinkActive="active" >Date</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/adverse-media']" style="margin-right: 5px;" routerLinkActive="active" >Adverse Media</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/tag-search']" style="margin-right: 5px;" routerLinkActive="active" >Tag Search</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/media-intelligence']" style="margin-right: 5px;" routerLinkActive="active" >Media Intelligence</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/p1-articles']" style="margin-right: 5px;"
              routerLinkActive="active">P1 Articles</a>
          </li>
      </ul>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item mr-2">
        <button class="btn btn-success" [routerLink]="['/home']" routerLinkActive="active" >Client</button>

    </li>
        <li class="nav-item">
            <button class="btn btn-danger" (click)="logout()">Logout</button>

        </li>
    </ul>
    
    <!-- Links -->
    
  </nav>
  
  <!-- <div class="container">
      <div class="col-lg col-md col-sm d-flex justify-content-center">
        <span class="mt-1">
          <button class="btn btn-primary" [routerLink]="['/adminhome']" style="margin-right: 5px;" routerLinkActive="active" routerLinkActive="active" >Event Details</button>
          <button class="btn btn-primary" [routerLink]="['/countrydetails']" style="margin-right: 5px;" routerLinkActive="active" routerLinkActive="active" >Country Details</button>
          <button class="btn btn-primary" [routerLink]="['/languagedetails']" style="margin-right: 5px;" routerLinkActive="active" routerLinkActive="active" >Language Details</button>
          <button class="btn btn-primary" [routerLink]="['/datedetails']" style="margin-right: 5px;" routerLinkActive="active" routerLinkActive="active" >Date Pattern Service</button>
          <button class="btn btn-primary"[routerLink]="['/adverse-media']" style="margin-right: 5px;" routerLinkActive="active" routerLinkActive="active" >Adverse Media</button>

        </span>
        </div>
  </div> -->
  <div class="wrapper">
      <div class="container-fluid">
          
          <div class="card">

            <!-- <span class="float-left">
                <a  floating="true" (click)="goback()"    ><fa-icon [icon]="faBackspace" class="mt-2 mb-2 ml-2" size="2x"></fa-icon></a>
                    
              </span> -->
              <div class="card-body">
                <a  floating="true" (click)="goback()"  class="float-left"  ><fa-icon [icon]="faBackspace" class="mt-2 mb-2 ml-2" size="2x"></fa-icon></a>

                <div class="d-flex justify-content-center m-t-1">
                   <div class="form-inline">
                      <input class="form-control mx-sm-3 " type="text" placeholder="Search By Name" [(ngModel)]="name" name="name" />
                      <button class="btn btn-primary" (click)="getByName(name)"  >Search</button>
                    </div>
                      
                  </div>
                  <div class="col-12 row d-flex justify-content-center mt-3">
                    <div class="form-group col-3">
                      <label for="exampleFormControlSelect1">Select Type</label>
                      <select class="form-control" [(ngModel)]="selected" name="selected"   (change)="getByFilter(selected)">
                        
                        <option *ngFor="let item of type;let i=index" [value]="item"  >{{item}}</option>
                        
                      </select>
                    </div>
                    <div class="form-group col-3">
                      <label for="exampleFormControlSelect1">Status</label>
                      <select class="form-control" [(ngModel)]="processStatus" name="processStatus" (change)="getByStatus(processStatus)">
                        <option value="" disabled>Select</option>
                        <option value="LEVEL1">Level 1</option>
                        <option value="LEVEL2">Level 2</option>
                        <option value="LEVEL3">Level 3</option>
                        
                      </select>
                    </div>
                    <div class="form-group col-3">
                      <label for="exampleFormControlSelect1">Processed Status</label>
                      <select class="form-control" [(ngModel)]="processedStatus" name="processedStatus" (change)="getByProcessedStatus(processedStatus)">
                        <option value=null disabled>Select Value</option>
                        <option value="adverseMediaDemo">Adverse Media Demo</option>
                        <option value="entityCategoryScores">Entity Category Scores</option>
                        <option value="adverseMediaDemo2">Adverse Media Demo 2</option>
                        <option value="gdeltTest1">GDELT-Test1</option>
                      </select>
                    </div>
                    
                  
                  <div class="form-group col-3">
                    <label for="exampleFormControlSelect1">Select Size</label>
                        <select class="form-control"  [(ngModel)]="size" (change)="getSearchResult()">
                            <option [ngValue]="10">10 items per page</option>
                            <option [ngValue]="15">15 items per page</option>
                            <option [ngValue]="20">20 items per page</option>
                        </select>
                    </div>
                </div>
                  
              </div>
          </div>
      </div>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="d-flex justify-content-center">
                  <div>
                    <h5>Aggregrate Scores</h5>
                    <span *ngFor="let t of aggSocres; index as i" >
                      <button class="badge badge-primary" (click)="tagSelected(t?.article,t?.aggregateScore)"><i class="fa fa-tag"></i>&nbsp;{{t.tag}} &nbsp;({{t.article.length}})</button>
                    </span>
                    
                    <div class="mt-4" >
                      <table class="table table-hover table-responsive">
                        <thead class="thead-light">
                          <tr>
                                
                            <th class="header" >Id</th>
                            <th class="header" >Scores</th>    
                            
                            <th class="header" >Action</th>
                
                            
                            
                          </tr>
                            
                        </thead>
                        <tbody >
                          <tr *ngFor="let tt of articleList; index as i" >
                            <td >{{tt?.id}}</td>
                            <td>{{tt?.score}}</td>
                            <td>
                              <div class="form-inline" style="padding: 5px;">   
                                
                                
        
                                    <button class="btn btn-primary mr-2" (click)="onSelectId(tt.id)" target="_blank">Select</button>
      
                                    
                            </div> 
                            </td>
                          </tr>
                        </tbody>
                    </table>

                      <span >
                          
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center " >
              <table class="table table-hover table-responsive">
              <thead class="thead-light">
                <tr>
                      
                  <th class="header" style="width: 2%;" >Id</th>
                  <th class="header" style="width: 18%;">Author</th>    
                  <th class="header" style="width: 25%;">Article Body</th>
                  <th class="header" style="width: 18%;">Tags</th>
                  <th class="header" style="width: 18%;">Adverse Entities</th>
                  <th class="header" style="width: 15%;">Article Link </th>
      
                  
                  
                </tr>
                  
              </thead>
              <tbody>
                <tr *ngFor="let item of advData" >
                  <td >{{item.articleId}}</td>
                  <td >{{item.article.author}}</td> 
                  <td>
                      <span>{{ (item.article.body.length>6)? (item.article.body | slice:0:200)+'....':(item.article.body) }}</span></td>
                  <td>
                    <div *ngFor="let tg of item.tags">
                      <li>{{tg}}</li>
                    </div>
                      <!-- <span style="line-break: anywhere;">{{item.tags}}</span>  -->
                  </td>
                  <td>
                    <div *ngFor="let ent of item.adverseEntity">
                      <li>{{ent.name}}</li>
                    </div>
                      <!-- <div *ngFor="let ent of item.adverseEntity" style="word-break: break-all;">{{ent.name+','}}</div> -->
                  </td>
                  
                  <td>
                      <div class="form-inline" style="padding: 5px;">   
                          <a href="{{item.article.articleUrl}}" target="_blank">
                          <fa-icon [icon]="faExternalLinkAlt" class="mr-2" size="2x"></fa-icon>
                          </a>
                          
                          
                              <button class="btn btn-primary mr-2" (click)="onClick(item.articleId)" target="_blank">Select</button>

                              
                      </div> 
                  </td>
                
                </tr>
                
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="12">
                    <div class="d-flex justify-content-center ">
                      <ngb-pagination 
                      [(page)]="page"
                      [collectionSize]="collectionSize" 
                      
                      [pageSize]="size" 
                      (pageChange)="refreshData($event)"
                      [maxSize]="3" 
                      
                      [rotate]="true" 
                      [boundaryLinks]="true"
                      >
                      </ngb-pagination>
                    
                      
                    </div>  
                  </td>
                </tr>
              </tfoot>
            </table>    
              </div>
          </div>
      
  </div>
