<nav class="navbar navbar-expand-sm bg-dark navbar-dark">
    <!-- Brand/logo -->
    <a class="navbar-brand" [routerLink]="['/home']" routerLinkActive="router-link-active" >
      <img src="assets/images/logo.png" alt="logo" style="height: 40px;">
    </a>
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/adverse-media']" style="margin-right: 5px;" routerLinkActive="active" >Adverse Media</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/tag-search']" style="margin-right: 5px;" routerLinkActive="active" >Tag Search</a>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto">

      <li class="nav-item">
        <button class="btn btn-success"  *ngIf="isAdmin"  [routerLink]="['/adminhome']" routerLinkActive="active" >Admin</button>

        <button class="btn btn-danger" (click)="logout()">Logout</button>
      </li>
    </ul>
    
    <!-- Links -->
    <!-- <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" href="#">Link 1</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link 2</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link 3</a>
      </li>
    </ul> -->
  </nav>
  <i class="fas fa-backspace"></i>
<div class="container-fluid">
    
        <div class="d-flex justify-content-center">
            
                <h1><fa-icon [icon]="faNewspaper"></fa-icon>Adverse media</h1>
            
        </div>
    
</div>
    
        <div class="container-fluid ">
          <div class="d-flex justify-content-center" >
            <input  width="50%" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" placeholder="Search by URL.">
            <button (click)="getSourceByURL()" style="background-color: cornflowerblue;" >Search</button>

          </div>

          
          <div class="d-flex justify-content-strart">
            
          </div>
          <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshData($event)">
            <option [ngValue]="10">10 items per page</option>
            <option [ngValue]="15">15 items per page</option>
            <option [ngValue]="20">20 items per page</option>
          </select>
            <button class="btn btn-info" id="create-btn" (click)="open(content)">Add New Source</button>
            
            <div class="d-flex justify-content-center table-responsive">
              
                 <table class="table card-table table-striped ">
                  <thead class="thead-light">
                    <tr>
                       <th>Source name</th>    
                      <th>Source url</th>
                      <th>Country</th>    
                      <th>Language</th>
                      <th>Actions</th>
                      
                    </tr>
                      
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of sources">
                      <td >{{item.dataSourceName}}</td>
                      <td >{{item.dataSourceURL}}</td> 
                      <td>{{item.country.name}}</td>
                      <td>{{item.language.name}}</td>
                      <td><button class="btn btn-warning" (click)="onClick($event)" id="{{item.dataSourceId}}" >
                        Edit
                      </button><button class="btn btn-primary" (click)="onClicknew($event)" id="{{item.dataSourceId}}" >
                        Add RSS
                      </button>
                    </td>
                    </tr>
                    
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="12">
                        <div class="d-flex justify-content-center ">
                          <ngb-pagination 
                          [(page)]="page"
                          [collectionSize]="collectionSize" 
                          
                          [pageSize]="pageSize" 
                          (pageChange)="refreshData($event)"
                          [maxSize]="3" 
                          
                          [rotate]="true" 
                          [boundaryLinks]="true"
                          >
                          </ngb-pagination>
                        
                          
                        </div>  
                      </td>
                    </tr>
                  </tfoot>
                </table>  
                
            </div>
        
         <ng-template #content let-modal>
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">Add new Data Source</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              
              <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="dataSourceName">Source Name</label>
                        <input type="text" formControlName="dataSourceName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dataSourceName.errors }" />
                        <div *ngIf="submitted && f.dataSourceName.errors" class="invalid-feedback">
                            <div *ngIf="f.dataSourceName.errors.required">Source Name is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="dataSourceURL">Source URL</label>
                        <input type="text" formControlName="dataSourceURL" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dataSourceURL.errors }" />
                        <div *ngIf="submitted && f.dataSourceURL.errors" class="invalid-feedback">
                            <div *ngIf="f.dataSourceURL.errors.required">Source Url is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="countryCode">Country Code</label>
                        
                        <select name="countryCode" formControlName="countryCode" id="country" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.countryCode.errors }">
                            <option *ngFor="let option of countries" [value]="option.code" >{{option.name}}</option>
                        </select>
                        <div *ngIf="submitted && f.countryCode.errors" class="invalid-feedback">
                            <div *ngIf="f.countryCode.errors.required">Country Code is required</div>
                        </div> 
                    </div>
                    <div class="form-group">
                        <label for="languageCode">Language Code</label>
                        <select name="languageCode" formControlName="languageCode" id="aantal" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.languageCode.errors }">
                            <option *ngFor="let option of language" [value]="option.code" >{{option.name}}</option>
                        </select>
                        <div *ngIf="submitted && f.languageCode.errors" class="invalid-feedback">
                            <div *ngIf="f.languageCode.errors.required">Language Code is required</div>
                        </div>
                    </div>
                    
                    <!-- <div class="form-group">
                        <label for="websiteTag">Website Tag</label>
                        <input type="text" formControlName="websiteTag" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.websiteTag.errors }" />
                        <div *ngIf="submitted && f.websiteTag.errors" class="invalid-feedback">
                            <div *ngIf="f.websiteTag.errors.required">Website Tag is required</div>
                        </div>
                    </div> -->
                    <div class="form-group">
                    <label for="websiteTag">Website Tag</label>
                    <ng-multiselect-dropdown
                        [placeholder]="'custom placeholder'"
                        [settings]="dropdownSettings"
                        [data]="dropdownList"
                        [(ngModel)]="websiteTag"
                        
                        
                        formControlName="websiteTag"
                        >
                        </ng-multiselect-dropdown>
                    <!-- <input type="text" formControlName="websiteTag" class="form-control"  [(ngModel)]="newdata.websiteTag" [ngClass]="{ 'is-invalid': submitted && fa.websiteTag.errors }" /> -->
                    <div *ngIf="submitted && f.websiteTag.errors" class="invalid-feedback">
                        <div *ngIf="f.websiteTag.errors.required">Website Tag is required</div>
                    </div>
                </div>
                    <!-- <div class="form-group">
                        <label for="crawlableNormally">Crawlable Normally</label>
                        <input type="text" formControlName="crawlableNormally" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.crawlableNormally.errors }" />
                        <div *ngIf="submitted && f.crawlableNormally.errors" class="invalid-feedback">
                            <div *ngIf="f.crawlableNormally.errors.required">Crawlable Normally is required</div>
                        </div>
                    </div> -->
                    <div class="form-group">
                      <label for="crawlableNormally">Crawlable Normally</label>
                      <div >
                      
                      <div class=" form-check form-check-inline">
                          <input data-md-icheck  formControlName="crawlableNormally" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.crawlableNormally.errors }" class="form-check-input" type="radio" id="inlineCheckbox1" value="true" > 
                          <label class="form-check-label" for="crawlableNormally">True</label>
                      </div>
                      
                    
                      <div class="form-check form-check-inline">
                            <input data-md-icheck  formControlName="crawlableNormally" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.crawlableNormally.errors }" class="form-check-input" type="radio" id="inlineCheckbox2" value="false" >
                            <label class="form-check-label" for="crawlableNormally">False</label>
                      </div>
                      <div *ngIf="submitted && f.crawlableNormally.errors" class="invalid-feedback">
                        <div *ngIf="f.crawlableNormally.errors.required">Crawlable Normally is required</div>
                      </div>
                    </div>
                  </div>
                    <div class="form-group">
                        <label for="datePattern">Date Pattern</label>
                        <select name="datePattern" formControlName="datePattern"   class="form-control" [ngClass]="{ 'is-invalid': submitted && f.datePattern.errors }">
                            <option *ngFor="let option of date" [value]="option.pattern" >{{option.pattern}}</option>
                        </select> 
                        <div *ngIf="submitted && f.datePattern.errors" class="invalid-feedback">
                            <div *ngIf="f.datePattern.errors.required">Date Pattern is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="sampleArticleUrl">Article URL</label>
                        <input type="text" formControlName="sampleArticleUrl" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.sampleArticleUrl.errors }" />
                        <div *ngIf="submitted && f.sampleArticleUrl.errors" class="invalid-feedback">
                            <div *ngIf="f.sampleArticleUrl.errors.required">Article URL is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="searchUrl">Search URL</label>
                        <input type="text" formControlName="searchUrl" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.searchUrl.errors }" />
                        <div *ngIf="submitted && f.searchUrl.errors" class="invalid-feedback">
                            <div *ngIf="f.searchUrl.errors.required">Search URL is required</div>
                        </div>
                    </div>
                    <!-- <h6>Dont have an account!<a [routerLink]="['/register']">Register Here</a></h6> -->
                    
                    <div class="modal-footer">
                      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                      <button [disabled]="loading" class="btn btn-success">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Save
                    </button>
                    </div>
                    <!-- <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div> -->
                </form>
              
            </div>
            
          </ng-template>
        </div>
        
    
