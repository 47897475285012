<nav class="navbar navbar-expand-sm bg-dark navbar-dark">
  <!-- Brand/logo -->
  <a class="navbar-brand" [routerLink]="['/home']" routerLinkActive="router-link-active" >
    <img src="assets/images/logo.png" alt="logo" style="height: 40px;">
  </a>
  <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/adminhome']" style="margin-right: 5px;" routerLinkActive="active" >Home</a>
      </li>
      
      <li class="nav-item">
          <a class="nav-link" [routerLink]="['/countrydetails']" style="margin-right: 5px;" routerLinkActive="active" >Countries</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/languagedetails']" style="margin-right: 5px;" routerLinkActive="active" >Languages</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/datedetails']" style="margin-right: 5px;" routerLinkActive="active" >Date</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/adverse-media']" style="margin-right: 5px;" routerLinkActive="active" >Adverse Media</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/tag-search']" style="margin-right: 5px;" routerLinkActive="active" >Tag Search</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/media-intelligence']" style="margin-right: 5px;" routerLinkActive="active" >Media Intelligence</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/p1-articles']" style="margin-right: 5px;"
            routerLinkActive="active">P1 Articles</a>
        </li>
    </ul>
  <ul class="navbar-nav ml-auto">
    <li class="nav-item mr-2">
      <button class="btn btn-success" [routerLink]="['/home']" routerLinkActive="active" >Client</button>

  </li>
      <li class="nav-item">
          <button class="btn btn-danger" (click)="logout()">Logout</button>

      </li>
  </ul>
  
  <!-- Links -->
  
</nav>
  <span class="float-left">
    <a  floating="true" (click)="goback()"    ><fa-icon [icon]="faBackspace" class="mt-2 mb-2 ml-2" size="2x"></fa-icon></a>
        
  </span>

  <div class="col-lg col-md col-sm">
    <div class="d-dlex justify-content-center">
      <button class="btn btn-info" (click)="open(content)" id="create-btn" >Add New Country</button>
      <select class="custom-select" style="width: auto" [(ngModel)]="pageSize" (ngModelChange)="refreshData()">
        <option [ngValue]="10">10 items per page</option>
        <option [ngValue]="15">15 items per page</option>
        <option [ngValue]="20">20 items per page</option>
      </select>  
    </div>
      
      <div class="d-flex justify-content-center table-responsive">
       
          
       
           <table class="table card-table table-striped ">
            <thead class="thead-light">
              <tr>
                 <th>Country Codes</th>    
                <th>Name</th>
                <th>Status</th>    
                <th>Synonyms</th>
                <th>Actions</th>
                
              </tr>
                
            </thead>
            <tbody>
              <tr *ngFor="let item of sources">
                <td >{{item.code}}</td>
                <td >{{item.name}}</td> 
                <td>{{item.status}}</td>
                <td>{{item.synonyms}}</td>
                <td><button class="btn btn-warning" id="{{item.code}}" (click)="onClick($event,contentone)" >
                  Edit
                </button>
              </td>
              </tr>
              <tr >
                            
                
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="12">
                  <div class="d-flex justify-content-center ">
                    <ngb-pagination 
                    [(page)]="page"
                    [collectionSize]="collectionSize" 
                    
                    [pageSize]="pageSize" 
                    (pageChange)="refreshData()"
                    [maxSize]="3" 
                    
                    [rotate]="true" 
                    [boundaryLinks]="true"
                    >
                    </ngb-pagination>
                  
                    
                  </div>  
                </td>
              </tr>
            </tfoot>
          </table>    
      </div>
    </div>
    <ng-template #content  let-modal >
        <div class="modal-header" style="padding: 1rem;">
          <h4 class="modal-title" id="modal-basic-title">Create Country</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form [formGroup]="updateForm" (ngSubmit)="onSubmitnew()">
                <div class="form-group">
                    <label for="name">Name</label>
                    <input type="text" formControlName="name" [(ngModel)]="countries.name"  class="form-control"  />
                    <div *ngIf="submitted && fa.name.errors" class="invalid-feedback">
                        <div *ngIf="fa.name.errors.required">Source Name is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="code">Code</label>
                    <input type="text" formControlName="code"  class="form-control" />
                    <div *ngIf="submitted && fa.code.errors" class="invalid-feedback">
                        <div *ngIf="fa.code.errors.required">Source Url is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="synonyms">Synonyms</label>
                    <input type="text" formControlName="synonyms"  class="form-control" />
                    <div *ngIf="submitted && fa.synonyms.errors" class="invalid-feedback">
                        <div *ngIf="fa.synonyms.errors.required">Source Url is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="status">Status</label>
                    <input type="text" formControlName="status"  class="form-control" />
                    <div *ngIf="submitted && fa.status.errors" class="invalid-feedback">
                        <div *ngIf="fa.status.errors.required">Source Url is required</div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                    <button type="submit" class="btn btn-success">
                         Save
                    </button>
                </div>
                
            </form>
            </div>
        </ng-template>
        <ng-template #contentone  let-modal >
          <div class="modal-header" style="padding: 1rem;">
            <h4 class="modal-title" id="modal-basic-title">Update Country</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            
              <form  (ngSubmit)="onUpdate()">
                  <div class="form-group">
                      <label for="name">Name</label>
                      <input type="text" ngDefaultControl name="name" [(ngModel)]="updatecountry.name"  class="form-control"  />
                      
                  </div>
                  <div class="form-group">
                      <label for="code">Code</label>
                      <input type="text" ngDefaultControl [(ngModel)]="updatecountry.code" name="code" [ngModelOptions]="{standalone: true}" class="form-control" />
                      
                  </div>
                  <div class="form-group">
                    <label for="code">Code3</label>
                    <input type="text" ngDefaultControl [(ngModel)]="updatecountry.code3" name="code3" [ngModelOptions]="{standalone: true}" class="form-control" />
                    
                </div>
                  <div class="form-group">
                      <label for="synonyms">Synonyms</label>
                      <input type="text"  ngDefaultControl [(ngModel)]="updatecountry.synonyms" name="synonyms" [ngModelOptions]="{standalone: true}" class="form-control" />
                      
                  </div>
                  <div class="form-group">
                      <label for="status">Status</label>
                      <input type="text" ngDefaultControl [(ngModel)]="updatecountry.status" name="status" [ngModelOptions]="{standalone: true}" class="form-control" />
                      
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                      <button type="submit" class="btn btn-success">
                           Save
                      </button>
                  </div>
                  
              </form>
              </div>
          </ng-template>
  