<nav class="navbar navbar-expand-sm bg-dark navbar-dark">
    <!-- Brand/logo -->
    <a class="navbar-brand" [routerLink]="['/home']" routerLinkActive="router-link-active" >
      <img src="assets/images/logo.png" alt="logo" style="height: 40px;">
    </a>
    <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/adminhome']" style="margin-right: 5px;" routerLinkActive="active" >Home</a>
        </li>
        
        <li class="nav-item">
            <a class="nav-link" [routerLink]="['/countrydetails']" style="margin-right: 5px;" routerLinkActive="active" >Countries</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/languagedetails']" style="margin-right: 5px;" routerLinkActive="active" >Languages</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/datedetails']" style="margin-right: 5px;" routerLinkActive="active" >Date</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/adverse-media']" style="margin-right: 5px;" routerLinkActive="active" >Adverse Media</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/tag-search']" style="margin-right: 5px;" routerLinkActive="active" >Tag Search</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/media-intelligence']" style="margin-right: 5px;" routerLinkActive="active" >Media Intelligence</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/p1-articles']" style="margin-right: 5px;"
              routerLinkActive="active">P1 Articles</a>
          </li>
      </ul>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item mr-2">
        <button class="btn btn-success" [routerLink]="['/home']" routerLinkActive="active" >Client</button>

    </li>
        <li class="nav-item">
            <button class="btn btn-danger" (click)="logout()">Logout</button>

        </li>
    </ul>
    
    <!-- Links -->
    
  </nav>
  <div class="container-fluid">
    <div class="row">
      <span class="float-left">
        <a  floating="true" (click)="goback()"    ><fa-icon [icon]="faBackspace" class="mt-2 mb-1 ml-2" size="2x"></fa-icon></a>
            
      </span>
    </div>
  </div>
  <div class="container-fluid" >
   
    <div class="row">
      
      <div class="col-lg-3 col-md-3 col-sm-3 mt-1" >
        
        <div class="card">
          <div class="card-body scrollb">
            <h5 class="card-title">Entity Category</h5>
            <!-- <ul class="advEnt" *ngFor="let score of entityCategoryScores|keyvalue;let i = index;">
              <li *ngFor="let entscr of score|keyvalue;let i = index;">
                {{entscr.value.data_breach}} <span class="badge">{{i+1}}</span></li>
            </ul> -->
            <ul class="advEnt" *ngFor="let score of entityCategoryScores|keyvalue;let i = index;">
              {{score.key}}
              <li style="    display: flex;
              /* justify-content: space-around; */
              background-color: #d4e2e691;
              padding: 0;" *ngFor="let entscr of score.value|keyvalue;let i = index;">
                <span style="flex: 1;
                text-align: center;
                padding: 8px;" >{{entscr.key}}</span> </li>
            </ul>
          </div>
        </div>
        <div class="card mt-2">
          <div class="card-body ">
            <h5 class="card-title">Adverse Entities</h5>
            
            <ul class="advEnt scrollb">
              <li  *ngFor="let nn of details.adverseEntity;let i=index" id="n{{i}}"
             
             (click)="checkSentence($event,nn,i)"
             [class.selected]="nn === selectedEntity"
             [ngStyle]="{backgroundColor: getColor(i)}"
              >
              
              <span class="badge">{{i+1}}</span> {{nn.name}}
              </li>
            </ul>
            
          </div>
        </div>
        <div class="card mt-2">
          <div class="card-body scrollb">
            <h5 class="card-title">Tags</h5>
            <ul class="advEnt">
              <li *ngFor="let tag of details.tags;let i=index"
              id="n{{i}}"
             (click)="checkTags($event,tag)"
             [class.selected]="tag === selectedTag"
             
              ><span class="badge">{{i+1}}</span> {{tag}}</li>
            </ul>
            
          </div>
        </div>
        <div class="card mt-2">
          <div class="card-body scrollb">
            <h5 class="card-title">Person</h5>
            <ul class="advEnt">
              <li *ngFor="let person of details.persons;let i = index;"><span class="badge">{{i+1}}</span>{{person}}</li>
            </ul>
            
          </div>
        </div>
        <div class="card mt-2">
          <div class="card-body scrollb">
            <h5 class="card-title">Organization</h5>
            <ul class="advEnt">
              <li *ngFor="let org of details.organizations;let i=index;"><span class="badge">{{i+1}}</span>{{org}}</li>
            </ul>
            
          </div>
        </div>
        <div class="card mt-2">
          <div class="card-body scrollb">
            <h5 class="card-title">Relations</h5>
            <ul class="advEnt">
              <li *ngFor="let rel of details?.relation;let i=index">{{rel}}</li>
            </ul>
            
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 mt-1"  >
        <div class="d-flex justify-content-center">
          
            <div class="card" >
              <div class="card-body scroll ">
                <div class="row justify-content-center">
                  <!-- <div class="col-6">
                    <img [src]="item.article.imageUrl" height="100%" width="50%" >
                    </div> -->
                  <h5 class="card-title">{{article?.title}}</h5>
                
                </div>
                <div class="row ">
            
                  <div class="col-4 d-flex justify-content-center">
                    <div class="form-inline">
                      <label >Process Status : </label>
                      <span  class="">{{article?.processStatus}}</span>
                    </div>
                </div>
                <div class="col-4 d-flex justify-content-center">
                  <div class="form-inline">
                    <label >Data Source : </label>
                    <span  class="">{{details?.dataSource}}</span>
                  </div>
                  
              </div>
              <div class="col-4 d-flex justify-content-center" >
                <div class="form-inline">
                  <label >Date: </label>
                  <span  class="">{{article?.publishDate|date}}</span>
                </div>
                
            </div>
                </div>
                <div class="row">
                  <div class="justify-content-center"#test>
                    <!-- <p id="Text" [innerHtml]="nn" ></p> -->
                    <p  >{{processedBody}}</p>
                    

                    
                  </div>
                  
                </div>
                
                <!-- [ngClass]="{'test': highlightColor}" -->
                
                
                
                
              </div>
            </div>
          
        </div>
        <div class="card mt-2" >
          

          
          <div class="card-body">
            <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
            <h5 class="card-title">Tagged Words</h5>
            <div class="row mx-auto"  >
              
              <form [formGroup]="updateForm" (ngSubmit)="onSubmitnew()">
                <div class="form-group">
                  <label for="inputTag">Input Tags</label>
                  <input type="text" formControlName="inputTag" [(ngModel)]="selectedTag" class="form-control"  />
                  
              </div>
                <div class="form-group" formArrayName="sentence">
                    <label for="sentence">Sentence</label>
                    <div *ngFor="let s of sen.controls;index as i">
                    <textarea type="text" [formControlName]="i" class="form-control new"></textarea>
                    <a class="btn btn-danger" (click)="delSent(i)">Remove</a>
                  </div>
                  <a class="btn btn-primary" (click)="addSent()">Add Sentence</a>
                </div>
                
              <div class="form-group" formArrayName="keywords">
                <label for="keywords">Keywords</label>
                <div *ngFor="let city of keyw.controls; index as i">
                <input type="text" [formControlName]="i"  class="form-control"  />
                <a class="btn btn-danger" (click)="delWords(i)">Remove</a>

              </div>
                <a class="btn btn-primary" (click)="addWords()">Add Keyword</a>
            </div>
                <button type="submit" class="btn btn-success">
                  Save
             </button>
              </form>
            </div>
            
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="form-group">
              <h5>Tag Threshold</h5>
              <p>{{tagThreshold}}</p>
            </div>
            <div class="form-group">
              <h5>Key String Dstance</h5>
              <p>{{keyStringDstance}}</p>
            </div>
          <div class="form-group">
            <h5>Tag Distances</h5>
            <ul class="advEnt" *ngFor="let tagsDist of tagDistances;let i = index;">
            
              <li style="    display: flex;
              /* justify-content: space-around; */
              /* background-color: #d4e2e691; */
              padding: 0;" [ngStyle]="{backgroundColor: getColorForTagged(isTagged)}">
                <span style="flex: 1;
                text-align: center;
                padding: 8px;" >{{tagsDist[0]}}</span> <span class="scorebadge">{{tagsDist[1]}}</span></li>
            </ul>
          </div>
          
          </div>
        </div>
        </div>
        
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 mt-1">
        <div class="card" >
          <div class="card-body ">
            <h5 class="card-title">Mapped Sentences</h5>
            <div >
              <div class="row mx-auto"  *ngFor="let n of newCheckedSent;let i=index" >
                {{i+1}}<p  disabled  >{{n}}</p>
              </div>
            </div>
            <br>
            -------------Tagged Sentences-----------
            <br>
            <div>
            <div class="row mx-auto"  *ngFor="let tg of newCheckedSenttags;let i=index" >
              {{i+1}}<p  disabled >{{tg}}</p>
            </div>
          </div>
        </div>
      </div>
        <!-- <div class="card mt-2" >
          <div class="card-body scrollb">
            <h5 class="card-title">Tagged Words</h5>
            <div class="row mx-auto"  >
              <p  disabled >{{selectedTag}}</p>
              
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>