import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightSentence'
})
export class HighlightSentencePipe implements PipeTransform {

  async transform(item, i) {
    let htmlBody = document.getElementById(`articledBodyWithHightlightSentence${i}`);
    let response;
    if(item.article){
      let articleBody = item.article.body;
      for(let body of item.article_body){
        if(body.sentence_start && body.sentence_end){
          let matchingString = articleBody.substr(body.sentence_start, body.sentence_end);
          articleBody = articleBody.replace(matchingString, `<b><i>${matchingString}</i></b>`);
        }
      }
      for(let entity of item.entities){
        let startIndex = articleBody.toLowerCase().indexOf(entity.entity.toLowerCase());
        if (startIndex != -1) {
            let endLength = entity.entity.length;
            let matchingString = articleBody.substr(startIndex, endLength);
            articleBody = articleBody.replace(matchingString, `<span style='color: red;'>${entity.entity}</span>`);
        }
      }
      response = await `<div>${articleBody}</div>`;
    }
    else{
      response = await `<div><p>Article body not found</p></div>`;
    }
    const returnData = htmlBody.innerHTML = response;
    return returnData;
  }

}
