<nav class="navbar navbar-expand-sm bg-dark navbar-dark">
  <!-- Brand/logo -->
  <a class="navbar-brand" [routerLink]="['/home']" routerLinkActive="router-link-active" >
    <img src="assets/images/logo.png" alt="logo" style="height: 40px;">
  </a>
  <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/adminhome']" style="margin-right: 5px;" routerLinkActive="active" >Home</a>
      </li>
      
      <li class="nav-item">
          <a class="nav-link" [routerLink]="['/countrydetails']" style="margin-right: 5px;" routerLinkActive="active" >Countries</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/languagedetails']" style="margin-right: 5px;" routerLinkActive="active" >Languages</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/datedetails']" style="margin-right: 5px;" routerLinkActive="active" >Date</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/adverse-media']" style="margin-right: 5px;" routerLinkActive="active" >Adverse Media</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/tag-search']" style="margin-right: 5px;" routerLinkActive="active" >Tag Search</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/media-intelligence']" style="margin-right: 5px;" routerLinkActive="active" >Media Intelligence</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/p1-articles']" style="margin-right: 5px;"
            routerLinkActive="active">P1 Articles</a>
        </li>
    </ul>
  <ul class="navbar-nav ml-auto">
    <li class="nav-item mr-2">
      <button class="btn btn-success" [routerLink]="['/home']" routerLinkActive="active" >Client</button>

  </li>
      <li class="nav-item">
          <button class="btn btn-danger" (click)="logout()">Logout</button>

      </li>
  </ul>
  
  <!-- Links -->
  
</nav>
  <span class="float-left">
    <a  floating="true" (click)="goback()"    ><fa-icon [icon]="faBackspace" class="mt-2 mb-2 ml-2" size="2x"></fa-icon></a>
        
  </span>
  
  <div class="container-fluid ">
    <!-- <div class="d-flex justify-content-center" >
    <input  width="50%" type="text" name="search" autocomplete="off" placeholder="Search">
    </div> -->
    <span class="float-left">
      <button class="btn btn-info" (click)="open(content)" id="create-btn">Add New Language</button>
          
    </span>
  
  <div class="d-flex justify-content-center table-responsive">
       <table class="table card-table table-striped ">
        <thead class="thead-light">
          <tr>
             <th>Code</th>    
            <th>Name</th>
            <th>Code3</th>
            <th>Status</th> 
            <th>Synonyms</th> 
            <th>Actions</th>
            
          </tr>
            
        </thead>
        <tbody>
          <tr *ngFor="let item of language">
            <td >{{item.code}}</td> 
            <td >{{item.name}}</td>
            
            <td>{{item.code3}}</td>
            <td>{{item.status}}</td>
            <td>{{item.synonyms}}</td>
            
            <td><button class="btn btn-warning" (click)="onClick($event,contentone)"  id="{{item.code}}" >
              Edit
            </button>
          </td>
          </tr>
          <tr >
                        
            
          </tr>
        </tbody>
      </table>    
  </div>
</div>
<ng-template #content  let-modal >
    <div class="modal-header" style="padding: 1rem;">
      <h4 class="modal-title" id="modal-basic-title">Create Language.</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      
        <form [formGroup]="createForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="code">Code</label>
                <input type="text" formControlName="code"   class="form-control"  />
                
            </div>
            <div class="form-group">
                <label for="name">Name</label>
                <input type="text" formControlName="name"  class="form-control" />
                
            </div>
            <div class="form-group">
                <label for="code3">Code3</label>
                <input type="text" formControlName="code3"  class="form-control" />
                
            </div>
            <div class="form-group">
                <label for="synonyms">Synonyms</label>
                <input type="text" formControlName="synonyms"  class="form-control" />
                
            </div>
            <div class="form-group">
                <label for="status">Status</label>
                <input type="text" formControlName="status"  class="form-control" />
                
            </div>
            
            
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                <button type="submit" class="btn btn-success">
                     Save
                </button>
            </div>
            
        </form>
        </div>
        </ng-template>
        <ng-template #contentone  let-modal >
            <div class="modal-header" style="padding: 1rem;">
              <h4 class="modal-title" id="modal-basic-title">Update Language.</h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              
                <form [formGroup]="createForm" (ngSubmit)="onUpdate()">
                    <div class="form-group">
                        <label for="code">Code</label>
                        <input type="text" ngDefaultControl [ngModelOptions]="{standalone: true}" [(ngModel)]="langdetail.code" name="langdetail.code"  class="form-control"  />
                        
                    </div>
                    <div class="form-group">
                        <label for="name">Name</label>
                        <input type="text" ngDefaultControl [ngModelOptions]="{standalone: true}" [(ngModel)]="langdetail.name" name="langdetail.name"    class="form-control" />
                        
                    </div>
                    <div class="form-group">
                        <label for="code3">Code3</label>
                        <input type="text" ngDefaultControl [ngModelOptions]="{standalone: true}" [(ngModel)]="langdetail.code3" name="langdetail.code3"        class="form-control" />
                        
                    </div>
                    <div class="form-group">
                        <label for="synonyms">Synonyms</label>
                        <input type="text" ngDefaultControl [ngModelOptions]="{standalone: true}" [(ngModel)]="langdetail.synonyms" name="langdetail.synonyms"       class="form-control" />
                        
                    </div>
                    <div class="form-group">
                        <label for="status">Status</label>
                        <input type="text" ngDefaultControl [ngModelOptions]="{standalone: true}" [(ngModel)]="langdetail.code3" name="langdetail.code3"       class="form-control" />
                        
                    </div>
                    
                    
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                        <button type="submit" class="btn btn-success">
                             Save
                        </button>
                    </div>
                    
                </form>
                </div>
                </ng-template>