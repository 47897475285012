import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from 'src/app/services/data.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, NgForm, FormControl } from '@angular/forms';
import {HttpClient,HttpRequest,HttpEvent,HttpEventType,HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import { error } from 'protractor';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import{ AuthService} from '../../services/auth.service'
import { faBackspace } from '@fortawesome/free-solid-svg-icons';
import{Location} from '@angular/common'
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-adminhome',
  templateUrl: './adminhome.component.html',
  styleUrls: ['./adminhome.component.css']
})
export class AdminhomeComponent implements OnInit {
  faBackspace=faBackspace
  eventData: any;
  name :any;
  IdeventData: any;

  page : number=0;
  size : any;
  order : any;
  orderBy : any;
  eventType: any = null;
  eventStatus : any =null;
  dateAttributeToFilter : any;
  rssFeedLink : any;
  startDate : any = null;
  endDate : any = null;
  newdata: any;
  pageSize: number=10;
  new2: { page: number; size: any; order: string; orderBy: string; eventType: any; eventStatus: any; dateAttributeToFilter: string; rssFeedLink: any; startDate: any; endDate: any; };
  collectionSize: any;



  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private data:DataService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private auth:AuthService,
    private location:Location,
    private spinner:NgxSpinnerService,
    
  ) { }

  ngOnInit() {
    this.getEventByFilter();
    //this.getEventById();
  }

logout(){
  this.auth.logout();
}
goback(){
  this.location.back()
}

getEventByFilter(){
  let abc ={
    
      "page" : this.page,
      "size" : this.pageSize,
      "order" : "ASC",
      "orderBy" : "createdDate",
      "eventType": this.eventType,
      "eventStatus" : this.eventStatus,
      "dateAttributeToFilter": "createdDate",
      "rssFeedLink" : null,
      "startDate" : this.startDate,
      "endDate" : this.endDate,
  
  }
  this.spinner.show()
  this.data.getEventBYFilter(abc).subscribe(
    data=>{
      this.eventData=data.data.content;
      this.collectionSize=data.data.totalElements
      console.log(this.eventData);
      this.spinner.hide()
      if(data.statusCode==200){
        console.log(data)
        this.modalService.dismissAll()
      }
    },
    (err:HttpErrorResponse)=>{
      console.log(err)
      this.spinner.hide()
    }
  )


}
refreshData(event) {
    
  let pages=(event-1)
  console.log(pages)
  this.new2 ={
                    "page" :pages ,
                  "size" : this.pageSize,
                  "order" : 'ASC',
                  "orderBy" : 'createdDate',
                  "eventType": this.eventType,
                  "eventStatus" : this.eventStatus,
                  "dateAttributeToFilter": "createdDate",
                  "rssFeedLink" : null,
                  "startDate" : this.startDate,
                  "endDate" : this.endDate,
               }
  this.data.getEventBYFilter(this.new2).subscribe(
    data=>{
      console.log(data)
      this.newdata=data['data']['content']
      this.eventData =this.newdata

    
    // .slice((this.page - 1) * this.size, (this.page - 1) * this.size + this.size);
  console.log(this.eventData)
    }
  )

  }
getEventById(){
  let id= this.name;
  this.data.getEventById(id).subscribe(
    data=>{
      this.IdeventData=data.data;
      if(data.statusCode==200){
        
        console.log(this.IdeventData)
        
      }

    },
    (err:HttpErrorResponse)=>{
      console.log(err)
    }
  )
}
clearFilter(){
  this.eventType = null;
  this.eventStatus =null;
  this.startDate  = null;
  this.endDate  = null;
  this.getEventByFilter();

}


}
