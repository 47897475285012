import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders,HttpResponse, HttpParams } from '@angular/common/http';


import { BehaviorSubject,Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import {User}  from "../models/user";

import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  profile: string;
  private userRole: BehaviorSubject<any>;
  public role: Observable<any>;
  
  constructor(
    private _http:HttpClient,
    private router:Router,
    @Inject("API_URL") public API_URL: any
  ) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
        this.userRole=new BehaviorSubject<any>(JSON.parse(localStorage.getItem('role')))
  this.role=this.userRole.asObservable();
      }
  public get userValue(): User {
    return this.userSubject.value;
}
public get roles():any{
return this.userRole.value

}
  
  


  userAuthentication(obj) {
    let data=new HttpParams()
    .set("username",obj.username)
    .set("password",obj.password)
    .set("grant_type","password")
    
    
    return this._http.post<any>(`${this.API_URL}/oauth/token`,data)
    .pipe(map(data => {
      // store user details and  token in local storage to keep user logged in between page refreshes
      if(data.access_token!==null){
      localStorage.setItem('user', JSON.stringify(data));
      this.userSubject.next(data);
      
      // console.log("token",this.userSubject.value)
      return data;
      
      }
      
  }));
    
  }
  checkProfile(){
    // this.profile=
    return this._http.get<any>(`${this.API_URL}/v1.0/clientsuser`).pipe(map(data=>{
      console.log(data.data.role)
      localStorage.setItem('role',JSON.stringify(data.data.role))
      this.userRole.next(data.data.role)
      console.log(this.userRole)
    }))
      
    
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    this.userSubject.next(null);
    this.router.navigate(['/login'])
    
    
    
}
}
