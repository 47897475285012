import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from 'src/app/services/data.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, NgForm, FormControl, FormArray } from '@angular/forms';
import {HttpClient,HttpRequest,HttpEvent,HttpEventType,HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import { error } from 'protractor';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import{ AuthService} from '../../services/auth.service'
import { faBackspace } from '@fortawesome/free-solid-svg-icons';
import{Location} from '@angular/common'
import { NgxSpinnerService } from 'ngx-spinner';
import { style } from '@angular/animations';
@Component({
  selector: 'app-adv-details',
  templateUrl: './adv-details.component.html',
  styleUrls: ['./adv-details.component.css']
})
export class AdvDetailsComponent implements OnInit {
  id: string;
  faBackspace=faBackspace;
  details: any={}
  adverseEntity: Array<any>=[];
  dataSrc: any;
  processedStatus: any;
  publishDate: any;
  article: any;
  sentenceTags: any=[];
  sentenceOffsets: any;
  off: any;
  senIndex: Array<any>=[];
  finalOffSet: Array<any>=[];
  color: boolean;
  highlightColor: boolean=false;
  
  @ViewChild("test") el: ElementRef
  ab: any;
  finalart:Array<any>;
  joinedStr: Array<string>=[];
  nn: string;
  nnn: any;
  processedBody: string;
  newElem: Array<number>=[]
  sentOff: Array<any>=[]
  finalSent: Array<any>;
  joinedFinalSent: Array<string>=[];
  selectedEntity: any;
  isChecked: string
  viewJoinedSen: string[];
  selectedTag: any;
  addcolor:Object={
    '0':'#27ae60',
    '1':'#ff4500'
  }
  cc: any=[];
  tags: any;
  persons: Array<any>=[];
  organizations: Array<any>=[];
  relations: any;
  sentMapped: any[];
  indexMapped: any[];
  newBody: string;
  checkedSent: any[];
  newCheckedSent: any;
  newCheckedSenttags: Array<any>;
  entitySelected:boolean=false
  tagSelected:boolean=false
  oldSentenceTags: any;
  newSentTag: any;
  newFiltered: any;
  selectedIndex: any=[];
  keyword:any
  entityCategoryScores: any={}
  isTagged: any;
  tagDistances: any=[];
  tagThreshold: any;
  keyStringDstance: any;
  public getColorForTagged(i :number) : string {
    switch( i) { 
      case 0 : return "#EA738DFF"
      case 1 : return "#97BC62FF"
    }
  }
  public getColor(i :number) : string {
    switch( i) { 
      case 0 : return "#9CC3D5FF"
      case 1 : return "#EA738DFF"
      case 2 : return "#97BC62FF"
      case 3 : return "#feb2a8"
      case 4 : return "#D85A7FFF"
      case 5 : return "#96ceb4 "
      case 6 : return "#ff6f69"
      case 7 : return "#8b9dc3"
      case 8 : return "#00aedb"
      case 9 : return "#feb2a8"
      case 10 : return "#9CC3D5FF"
      case 11 : return "#EA738DFF"
      case 12 : return "#97BC62FF"
      case 13 : return "#feb2a8"
      case 14 : return "#D85A7FFF"
      case 15 : return "#96ceb4 "
      case 16 : return "#ff6f69"
      case 17 : return "#8b9dc3"
      case 18 : return "#00aedb"
      case 19 : return "#feb2a8"
      case 20 : return "#9CC3D5FF"
      case 21: return "#EA738DFF"
      case 22 : return "#97BC62FF"
      case 23 : return "#feb2a8"
      case 24 : return "#D85A7FFF"
      case 25 : return "#96ceb4 "
      case 26 : return "#ff6f69"
      case 27 : return "#8b9dc3"
      case 28 : return "#00aedb"
      case 29 : return "#feb2a8"
      case 30 : return "#9CC3D5FF"
      case 31: return "#EA738DFF"
      case 32 : return "#97BC62FF"
      case 33 : return "#feb2a8"
      case 34 : return "#D85A7FFF"
      case 35 : return "#96ceb4 "
      case 36 : return "#ff6f69"
      case 37 : return "#8b9dc3"
      case 38 : return "#00aedb"
      case 39 : return "#feb2a8"
      case 40 : return "#9CC3D5FF"
      case 41: return "#EA738DFF"
      case 42 : return "#97BC62FF"
      case 43 : return "#feb2a8"
      case 44 : return "#D85A7FFF"
      case 45 : return "#96ceb4 "
      case 46 : return "#ff6f69"
      case 47 : return "#8b9dc3"
      case 48 : return "#00aedb"
      case 49 : return "#feb2a8"
      default: return "black"
    }
  }
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private data:DataService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private auth:AuthService,
    private location:Location,
    private spinner:NgxSpinnerService,
    
  ) {
    // console.log(this.el)
    
   }

  ngOnInit(): void {
    this.id=this.activatedRoute.snapshot.paramMap.get('id')
    console.log(this.id)
    this.getById(this.id);
      // this.addColor();
      
    
      
  }
  ngAfterViewInit():void{
    console.log("afterinit");
    
    
  }
getById(id){
  this.spinner.show("Highlight")
this.data.getAdvById(id).subscribe(
  data=>{
    if(data.statusCode==200){
      console.log(data)
      this.spinner.hide("Highlight")
      this.details=data['data']['content']['0']
     console.log(this.details)
     console.log(this.details.article)
     this.article=this.details.article
     this.processedBody=this.article.processedBody
     this.sentenceOffsets=this.details.sentenceOffset
     this.adverseEntity=this.details.adverseEntity
     this.tags=this.details.tags
     this.persons=this.details.persons
     this.organizations=this.details.organizations
     this.relations=this.details.relations
     this.sentenceTags=this.details.sentenceTags
     this.entityCategoryScores=this.details.entityCategoryScores
     console.log(this.sentenceTags)
     
    //  console.log(this.processedBody)
    this.highlightAdvEntity();
    this.highlightTag();
    this.entityScore();
    }
  },
  (err:HttpErrorResponse)=>{
    console.log(err)
    this.spinner.hide()
  }
)
}
highlightAdvEntity(){
  const neww=this.adverseEntity.map((el,index )=> {
       
    return el.sentenceIndex
  });
  this.indexMapped=neww.map((x,i)=>{
    
   this.cc=x.map((element,ind) => {
     const dd=this.sentenceOffsets.find((y,index)=>{
            if(element==index)
            return y
          })
      
      return x[ind]=dd
        
    });
    
      
   return this.cc
  })
  // console.log(bb)
  this.sentMapped=this.indexMapped.map((x,i)=>{
    // console.log(x)
    const kk=x.map(ele => {
      const string = this.article.processedBody

      const usingSplit = string.split('');
      const usingSpread = [...string];
      const usingArrayFrom = Array.from(string);
      const usingObjectAssign = Object.assign([], string);
      // console.log(usingObjectAssign)
      this.finalart=usingObjectAssign.slice(ele[0],ele[1])
      // console.log("art",this.finalart.join(''))
        return this.finalart.join('')
      
      
    });
    return x[i]=kk
    
  })
  // console.log("sent map",this.sentMapped)
  this.setHtml();
  

}
setHtml(){
  // console.log(this.sentMapped)
  const newmm=this.sentMapped.map((x,index)=>{
    const tt=x.map(ele=>{
      // console.log(ele)
       this.processedBody=this.processedBody.replace(ele,`<span style="background-color:${this.getColor(index)}">` + ele + `</span>`)
      // console.log(this.processedBody)
    })
    // return x[index]=tt
  })
  // console.log(newmm)
  setTimeout(() => {
    
    //  console.log("afterview",this.processedBody)
     this.el.nativeElement.innerHTML=this.processedBody
    
     
    
  
 }, 100);
}
  
checkSentence(e,n,i){
  console.log(n);
  console.log(i)
  this.selectedEntity=n;
  this.entitySelected=true
  this.newCheckedSenttags=[]
  this.checkedSent=this.sentMapped.filter((x,index)=>{
    if(index==i){
      return x
    }
  })
  this.newCheckedSent=this.checkedSent['0']
  
  
  
}
checkTags(e,tag){
  this.selectedTag=tag
  console.log(tag)
  this.tagSelected=true
  const dd=this.oldSentenceTags
  this.newCheckedSenttags=[]
  for(let i=0;i<dd.length;i++){ 
    //0-8 -- 
    for(let j=0;j<dd[i][0].length;j++){
      // console.log(dd[i][0][j][0])
      if(dd[i][0][j][0]==tag){
        console.log(dd[i][1])
        this.newCheckedSenttags.push(dd[i][1])
      }
    }
    // 
  }
  console.log(this.newSentTag)
  // this.sentenc.patchValue(this.newCheckedSenttags)
 
  
}
highlightTag(){
  this.oldSentenceTags=this.sentenceTags
  // console.log(this.oldSentenceTags)
  this.newSentTag=this.oldSentenceTags.map((x,i)=>{
    // const cc=x.map(y=>{
    //   return y['1']
    // })
    // return x[i]=cc
    const cc=this.sentenceOffsets.find((y,ind)=>{
      if(ind==x['1']){
        return y
      }
    })
    const string = this.article.processedBody

      const usingSplit = string.split('');
      const usingSpread = [...string];
      const usingArrayFrom = Array.from(string);
      const usingObjectAssign = Object.assign([], string);
      const newww=usingObjectAssign.slice(cc[0],cc[1]).join('')
    return x['1']=newww
  }) 
}
entityScore(){
  let evilResponseProps = Object.keys(this.entityCategoryScores);
// Step 2. Create an empty array.
    let goodResponse = [];
    // Step 3. Iterate throw all keys.
    for (var prop of evilResponseProps) { 
    goodResponse.push(evilResponseProps[prop]);
    }
    console.log("scores",evilResponseProps)
    console.log("scores",goodResponse)

}
public updateForm: FormGroup= new FormGroup({
  sentence:new FormArray ([]),
 inputTag:new FormControl ('', Validators.required),
 keywords:new FormArray([
  
  
]),
 
})
get fa() { return this.updateForm.controls; }
onSubmitnew(){

  console.log(this.keyw.value);
  console.log(this.sen.value);
  // console.log(this.updateForm.value.keywords.split(','))
  console.log(this.updateForm.value)
  this.data.getDistances(this.updateForm.value).subscribe(
    data=>{
    
    
      console.log(data)
      this.isTagged=data.isTagged
      this.tagThreshold=data.tagThreshold
      this.keyStringDstance=data.keyStringDstance
      this.tagDistances=data.tagDistances
      
      console.log(this.isTagged)
    
  },
  (err:HttpErrorResponse)=>{
    console.log(err)
  }
  )
  
}
get sen():FormArray{
  return this.updateForm.get('sentence') as FormArray;
}

get keyw(): FormArray {
  return this.updateForm.get('keywords') as FormArray;
}
addSent(){
  this.sen.push(new FormControl())
  
}
delSent(i){
  this.sen.removeAt(i)
}
delWords(i){
  this.keyw.removeAt(i)
}
addWords() {
  this.keyw.push(new FormControl());
}

logout(){
  this.auth.logout();
}
goback(){
  this.location.back()
}
}
