<nav class="navbar navbar-expand-sm bg-dark navbar-dark">
    <!-- Brand/logo -->
    <a class="navbar-brand" [routerLink]="['/home']" routerLinkActive="router-link-active" >
      <img src="assets/images/logo.png" alt="logo" style="height: 40px;">
    </a>
    <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <button class="btn btn-danger" (click)="logout()">Logout</button>
        </li>
      </ul>
    
    <!-- Links -->
    <!-- <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" href="#">Link 1</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link 2</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link 3</a>
      </li>
    </ul> -->
  </nav>
  <span class="float-left">
    <a  floating="true" (click)="goback()"    ><fa-icon [icon]="faBackspace" class="mt-2 mb-2 ml-2" size="2x"></fa-icon></a>
        
  </span>
  
  <div class="container-fluid">
      <div class="d-flex justify-content-center">
          <h1>Edit data</h1>
          
      </div>
      
  </div>
<div class="container-fluid">
    
  <div class="d-flex justify-content-center">
    <div class=" card">
        <div class="d-flex justify-content-center">
            
            <button class="btn btn-primary" (click)="open(content)">Update </button>
         </div>  
        <div class="card-body">
            <table class="table card-table   table-hover">
                <thead class="thead-light">
                  <tr>
                      
                     <th>Property</th>    
                    <th>Value</th>
                    
                    
                  </tr>
                    
                </thead>
                <tbody>
                    
                    <tr>
                        <td>Data Source ID </td>
                        <td>{{newdata?.dataSourceId}}</td>
                      </tr>
                      <tr>
                        <td>Data Source Name </td>
                        <td>{{newdata?.dataSourceName}}</td>
                      </tr>
                      <tr>
                        <td>Data Source URL</td>
                        <td>{{newdata?.dataSourceURL}}</td>
                      </tr>
                      <tr>
                        <td>Country</td>
                        <td>{{country?.name}}</td>
                      </tr>
                        <tr>
                            <td>Country Code</td>
                            <td>{{country?.code}}</td>
                        </tr>
                        <tr>
                            <td>Language</td>
                            <td>{{lang?.name}}</td>
                          </tr>
                            <tr>
                                <td>Language Code</td>
                                <td>{{lang?.code}}</td>
                            </tr>
                            <tr>
                                <td>Website Tags</td>
                                <td>{{newdata?.websiteTag}}</td>
                            </tr>
                            <tr>
                                <td>Crawlable Normally </td>
                                <td>{{newdata?.crawlableNormally}}</td>
                            </tr>
                            <tr>
                                <td>Date Pattern </td>
                                <td>{{newdata?.datePattern}}</td>
                            </tr>
                            <tr>
                                <td>Search Url </td>
                                <td>{{newdata?.searchUrl}}</td>
                            </tr>
                            <tr>
                                <td>Harvesting Links Selector </td>
                                <td>{{newdata?.harvestingLinksSelector}}</td>
                            </tr>
                            <tr>
                                <td>Harvesting Date Selector </td>
                                <td>{{newdata?.harvestDateSelector}}</td>
                            </tr>
                            <tr>
                                <td>Next Page Selector</td>
                                <td>{{newdata?.nextPageSelector}}</td>
                            </tr>
                            <tr>
                                <td>Article title selector</td>
                                <td>{{newdata?.articleTitleSelector}}</td>
                             </tr>
                             <tr>
                                <td>Article body selector</td>
                                <td>{{newdata?.articleBodySelector}}</td>
                            </tr>
                            <tr>
                                <td>Article date selector</td>
                                <td>{{newdata?.articleDateSelector}}</td>
                            </tr>
                            <tr>
                                <td>Article image URL selector</td>
                                <td>{{newdata?.articleImageUrlSelector}}</td>
                            </tr>
                            <tr>
                                <td>News Author Selector</td>
                                <td>{{newdata?.newsAuthorSelector}}</td>
                            </tr>
                            <tr>
                                <td>RSS Feed Status</td>
                                <td>{{newdata?.rssFeedStatus}}</td>
                            </tr>
                            <tr>
                                <td>Sample Article Url</td>
                                <td>{{newdata?.sampleArticleUrl}}</td>
                            </tr>
                            <tr>
                                <td>Created Date</td>
                                <td>{{newdata?.createdDate}}</td>
                            </tr>
                            
                            <tr>
                                <td>Last Modified Date</td>
                                <td>{{newdata?.lastModifiedDate}}</td>
                            </tr>
                   
                </tbody>
            </table>
        

         
        </div>
        </div>
    </div>
    
    <ng-template #content  let-modal >
        <div class="modal-header" style="padding: 1rem;">
          <h4 class="modal-title" id="modal-basic-title">Update Data Source</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          
            <form [formGroup]="updateForm" (ngSubmit)="onSubmitnew()">
                <div class="form-group">
                    <label for="dataSourceName">Source Name</label>
                    <input type="text" formControlName="dataSourceName" [(ngModel)]="newdata.dataSourceName" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.dataSourceName.errors }" />
                    <div *ngIf="submitted && fa.dataSourceName.errors" class="invalid-feedback">
                        <div *ngIf="fa.dataSourceName.errors.required">Source Name is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="dataSourceURL">Source URL</label>
                    <input type="text" formControlName="dataSourceURL" [(ngModel)]="newdata.dataSourceURL" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.dataSourceURL.errors }" />
                    <div *ngIf="submitted && fa.dataSourceURL.errors" class="invalid-feedback">
                        <div *ngIf="fa.dataSourceURL.errors.required">Source Url is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="countryCode">Country Code--{{country.name}}</label>
                    <!-- <ng-multiselect-dropdown
                        [placeholder]="'custom placeholder'"
                        [data]="countries"
                        [(ngModel)]="selectedItems"
                        [settings]="dropdownSettings"
                        formControlName="countryCode"
                        >
                    </ng-multiselect-dropdown> -->
                    <select name="countryCode" formControlName="countryCode"  id="aantal" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.countryCode.errors }">
                        <option *ngFor="let option of countries" [value]="option.code" >{{option.name}}</option>
                    </select>
                    <div *ngIf="submitted && fa.countryCode.errors" class="invalid-feedback">
                        <div *ngIf="fa.countryCode.errors.required">Country Code is required</div>
                    </div> 
                </div>
                <div class="form-group">
                    <label for="languageCode">Language Code--{{lang.name}}</label>
                    <select name="languageCode" formControlName="languageCode" id="aantal" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.languageCode.errors }">
                        <option *ngFor="let option of language" [value]="option.code" >{{option.name}}</option>
                    </select>
                    <div *ngIf="submitted && fa.languageCode.errors" class="invalid-feedback">
                        <div *ngIf="fa.languageCode.errors.required">Language Code is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="websiteTag">Website Tag</label>
                    <ng-multiselect-dropdown
                        [placeholder]="'custom placeholder'"
                        [settings]="dropdownSettings"
                        [data]="dropdownList"
                        [(ngModel)]="websiteTag"
                        
                        
                        formControlName="websiteTag"
                        >
                        </ng-multiselect-dropdown>
                    <!-- <input type="text" formControlName="websiteTag" class="form-control"  [(ngModel)]="newdata.websiteTag" [ngClass]="{ 'is-invalid': submitted && fa.websiteTag.errors }" /> -->
                    <div *ngIf="submitted && fa.websiteTag.errors" class="invalid-feedback">
                        <div *ngIf="fa.websiteTag.errors.required">Website Tag is required</div>
                    </div>
                </div>
                <!-- <div class="form-group">
                    <label for="crawlableNormally">Crawlable Normally</label>
                    
                    <input type="text" formControlName="crawlableNormally" class="form-control" [(ngModel)]="newdata.crawlableNormally" [ngClass]="{ 'is-invalid': submitted && fa.crawlableNormally.errors }" />
                    <div *ngIf="submitted && fa.crawlableNormally.errors" class="invalid-feedback">
                        <div *ngIf="fa.crawlableNormally.errors.required">Crawlable Normally is required</div>
                    </div>
                </div> -->
                <div class="form-group">
                    <label for="crawlableNormally">Crawlable Normally</label>
                    <div >
                    
                    <div class=" form-check form-check-inline">
                        <input data-md-icheck [(ngModel)]="newdata.crawlableNormally" formControlName="crawlableNormally" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.crawlableNormally.errors }" class="form-check-input" type="radio" id="inlineCheckbox1" [value]="true" > 
                        <label class="form-check-label" for="crawlableNormally">True</label>
                    </div>
                    
                  
                    <div class="form-check form-check-inline">
                          <input data-md-icheck [(ngModel)]="newdata.crawlableNormally"  formControlName="crawlableNormally" class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.crawlableNormally.errors }" class="form-check-input" type="radio" id="inlineCheckbox2" [value]="false" >
                          <label class="form-check-label" for="crawlableNormally">False</label>
                    </div>
                    <div *ngIf="submitted && fa.crawlableNormally.errors" class="invalid-feedback">
                      <div *ngIf="fa.crawlableNormally.errors.required">Crawlable Normally is required</div>
                    </div>
                  </div>
                </div>
                
                <div class="form-group">
                    <label for="datePattern">Date Pattern--{{newdata.datePattern}}</label>
                    <select name="datePattern" formControlName="datePattern" [(ngModel)]="newdata.datePattern"  class="form-control" [ngClass]="{ 'is-invalid': submitted && fa.datePattern.errors }">
                        <option *ngFor="let option of date" [value]="option.pattern" >{{option.pattern}}</option>
                    </select>                    
                    <div *ngIf="submitted && fa.datePattern.errors" class="invalid-feedback">
                        <div *ngIf="fa.datePattern.errors.required">Date Pattern is required</div>
                    </div>
                </div>
                
                <div class="form-group">
                    <label for="searchUrl">Search URL</label>
                    <input type="text" formControlName="searchUrl" class="form-control" [(ngModel)]="newdata.searchUrl" [ngClass]="{ 'is-invalid': submitted && fa.searchUrl.errors }" />
                    <div *ngIf="submitted && fa.searchUrl.errors" class="invalid-feedback">
                        <div *ngIf="fa.searchUrl.errors.required">Search URL is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="harvestingLinksSelector">Harvesting Link Selector</label>
                    <input type="text" formControlName="harvestingLinksSelector" class="form-control"  [(ngModel)]="newdata.harvestingLinksSelector"[ngClass]="{ 'is-invalid': submitted && fa.harvestingLinksSelector.errors }" />
                    <div *ngIf="submitted && fa.harvestingLinksSelector.errors" class="invalid-feedback">
                        <div *ngIf="fa.harvestingLinksSelector.errors.required">Harvesting Link Selector is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="harvestDateSelector"> Harvest Date Selector </label>
                    <input type="text" formControlName="harvestDateSelector" class="form-control" [(ngModel)]="newdata.harvestDateSelector" [ngClass]="{ 'is-invalid': submitted && fa.harvestDateSelector.errors }" />
                    <div *ngIf="submitted && fa.harvestDateSelector.errors" class="invalid-feedback">
                        <div *ngIf="fa.harvestDateSelector.errors.required"> Harvest Date Selector is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="nextPageSelector">Next Page Selector</label>
                    <input type="text" formControlName="nextPageSelector" class="form-control" [(ngModel)]="newdata.nextPageSelector" [ngClass]="{ 'is-invalid': submitted && fa.nextPageSelector.errors }" />
                    <div *ngIf="submitted && fa.nextPageSelector.errors" class="invalid-feedback">
                        <div *ngIf="fa.nextPageSelector.errors.required">Next Page Selector is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="articleTitleSelector">Article Title Selector</label>
                    <input type="text" formControlName="articleTitleSelector" class="form-control" [(ngModel)]="newdata.articleTitleSelector" [ngClass]="{ 'is-invalid': submitted && fa.articleTitleSelector.errors }" />
                    <div *ngIf="submitted && fa.articleTitleSelector.errors" class="invalid-feedback">
                        <div *ngIf="fa.articleTitleSelector.errors.required">Article Title Selector is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="articleBodySelector">Article Body Selector</label>
                    <input type="text" formControlName="articleBodySelector" class="form-control" [(ngModel)]="newdata.articleBodySelector" [ngClass]="{ 'is-invalid': submitted && fa.articleBodySelector.errors }" />
                    <div *ngIf="submitted && fa.articleBodySelector.errors" class="invalid-feedback">
                        <div *ngIf="fa.articleBodySelector.errors.required">Article Body Selector is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="articleDateSelector">Article Date Selector</label>
                    <input type="text" formControlName="articleDateSelector" class="form-control" [(ngModel)]="newdata.articleDateSelector" [ngClass]="{ 'is-invalid': submitted && fa.articleDateSelector.errors }" />
                    <div *ngIf="submitted && fa.articleDateSelector.errors" class="invalid-feedback">
                        <div *ngIf="fa.articleDateSelector.errors.required">Article Date Selector is required</div>
                    </div>
                </div>
                
                <div class="form-group">
                    <label for="articleImageUrlSelector">Article Image Url Selector</label>
                    <input type="text" formControlName="articleImageUrlSelector" class="form-control" [(ngModel)]="newdata.articleImageUrlSelector" [ngClass]="{ 'is-invalid': submitted && fa.articleImageUrlSelector.errors }" />
                    <div *ngIf="submitted && fa.articleImageUrlSelector.errors" class="invalid-feedback">
                        <div *ngIf="fa.articleImageUrlSelector.errors.required">Article Image Url Selector is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="newsAuthorSelector">News Author Selector</label>
                    <input type="text" formControlName="newsAuthorSelector" class="form-control" [(ngModel)]="newdata.newsAuthorSelector" [ngClass]="{ 'is-invalid': submitted && fa.newsAuthorSelector.errors }" />
                    <div *ngIf="submitted && fa.newsAuthorSelector.errors" class="invalid-feedback">
                        <div *ngIf="fa.newsAuthorSelector.errors.required">News Author Selector is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="rssFeedStatus">RSS Feed Status</label>
                    <input type="text" formControlName="rssFeedStatus" class="form-control" [(ngModel)]="newdata.rssFeedStatus" [ngClass]="{ 'is-invalid': submitted && fa.rssFeedStatus.errors }" />
                    <div *ngIf="submitted && fa.rssFeedStatus.errors" class="invalid-feedback">
                        <div *ngIf="fa.rssFeedStatus.errors.required">News Author Selector is required</div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="sampleArticleUrl">Article URL</label>
                    <input type="text" formControlName="sampleArticleUrl" class="form-control" [(ngModel)]="newdata.sampleArticleUrl" [ngClass]="{ 'is-invalid': submitted && fa.sampleArticleUrl.errors }" />
                    <div *ngIf="submitted && fa.sampleArticleUrl.errors" class="invalid-feedback">
                        <div *ngIf="fa.sampleArticleUrl.errors.required">Article URL is required</div>
                    </div>
                </div>
                <!-- <h6>Dont have an account!<a [routerLink]="['/register']">Register Here</a></h6> -->
                
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Close</button>
                    <button [disabled]="loading" class="btn btn-success">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Save
                    </button>
                </div>
                
                <!-- <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div> -->
            </form>
        </div>
        
            
      </ng-template>
</div>