import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from 'src/app/services/data.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, NgForm, FormControl } from '@angular/forms';
import {HttpClient,HttpRequest,HttpEvent,HttpEventType,HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import { error } from 'protractor';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import{ AuthService} from '../../services/auth.service'
import { faBackspace } from '@fortawesome/free-solid-svg-icons';
import{Location} from '@angular/common'

@Component({
  selector: 'app-datedetails',
  templateUrl: './datedetails.component.html',
  styleUrls: ['./datedetails.component.css']
})
export class DatedetailsComponent implements OnInit {
  faBackspace=faBackspace
  datepattern: any;
  closeResult: string;
  datedetail: any;
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private data:DataService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private auth:AuthService,
    private location:Location,
  ) { }

  ngOnInit(): void {
    this.data.getDatePattern().subscribe(
      data=>{
        console.log(data)
        this.datepattern=data.data
      }
    )
  }
  public updateForm: FormGroup= new FormGroup({
    pattern:new FormControl ('', Validators.required),
   exampleDate:new FormControl ('', Validators.required),
   
  })
  // public updateFormt: FormGroup= new FormGroup({
  //   datePatternID:new FormControl ('', Validators.required),
  //  exampleDate:new FormControl ('', Validators.required),
  //  pattern:new FormControl ('', Validators.required),
  // })
  onSubmitnew(){
    console.log(this.updateForm.value)
    this.data.createDate(this.updateForm).subscribe(
      data=>{
        if(data.statusCode==200){
          this.modalService.dismissAll()
          console.log(data)
        }
        
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
      }
    )
  }
  onUpdate(){
    let abc={"datePatternID":this.datedetail.datePatternID,"pattern":this.datedetail.pattern,"exampleDate":this.datedetail.exampleDate}
    console.log(abc)
    this.data.updateDatePattern(abc).subscribe(
      data=>{
        if(data.statusCode==200){
          this.modalService.dismissAll
        console.log(data)
        }
      },
      (err:HttpErrorResponse)=>{
        console.log(err)
      }
    )
  }
  onClick(event,contentone){
   

    let target = event.target || event.srcElement || event.currentTarget;
    let idAttr = target.attributes.id;
    let id = idAttr.nodeValue;
    
    console.log(id); 
    this.data.getDateById(id).subscribe(
      data=>{
        console.log(data)
        this.datedetail=data['data']
        this.modalService.open(contentone, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
    )

  }
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  logout(){
    this.auth.logout();
  }
  goback(){
    this.location.back()
  }
}
