import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule,HTTP_INTERCEPTORS} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {AppInterceptor} from './interceptor/http.interceptor';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { EditsourceComponent } from './components/editsource/editsource.component';
import { RssfeedComponent } from './components/rssfeed/rssfeed.component';
import { RssdetailsComponent } from './components/rssdetails/rssdetails.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './services/auth.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ExtractedDataComponent } from './components/extracted-data/extracted-data.component';
import { ImageLabellingComponent } from './components/image-labelling/image-labelling.component';
import {  NgxSpinnerModule } from 'ngx-spinner';
import { AdminhomeComponent } from './components/adminhome/adminhome.component';
import { CountrydetailsComponent } from './components/countrydetails/countrydetails.component';
import { LanguagedetailsComponent } from './components/languagedetails/languagedetails.component';
import { DatedetailsComponent } from './components/datedetails/datedetails.component';
import { AdverseMediaComponent } from './components/adverse-media/adverse-media.component';
import { AdvDetailsComponent } from './components/adv-details/adv-details.component';
import { HighlightDirective } from './directives/highlight.directive';
import { TestComponent } from './components/test/test.component';
import { TagSearchComponent } from './components/tag-search/tag-search.component';
import { RegisterUserComponent } from './components/register-user/register-user.component';
import { MediaIntelligence } from './components/media-intelligence/media-intelligence';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { P1ArticlesComponent } from './components/p1-articles/p1-articles.component';
import { HighlightSentencePipe } from './pipes/highlight-sentence.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    EditsourceComponent,
    RssfeedComponent,
    RssdetailsComponent,
    ExtractedDataComponent,
    ImageLabellingComponent,
    AdminhomeComponent,
    CountrydetailsComponent,
    LanguagedetailsComponent,
    DatedetailsComponent,
    AdverseMediaComponent,
    AdvDetailsComponent,
    HighlightDirective,
    TestComponent,
    TagSearchComponent,
    RegisterUserComponent,
    MediaIntelligence,
    P1ArticlesComponent,
    HighlightSentencePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(
      {
        timeOut: 5000,
        positionClass: "toast-top-right",
        preventDuplicates: true,
        closeButton: true,
        tapToDismiss:true
      }
    ),
    FontAwesomeModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgSelectModule,
    Ng2SearchPipeModule,
    NgxSpinnerModule,
    NgxSliderModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    
    { provide: "API_URL", useValue: "http://api.ame.kychub.com" },
    //{ provide: "API_URL", useValue: "http://api.ame.dev.kychub.com" },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
   
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
